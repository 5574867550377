import React from "react";
import Content from "../../UI/Content";
import Thumbnail from "../../UI/Thumbnail";

const About = ({ title, heading, content, thumb }) => {
  //   content = "<strong style={{color:'red'}}>S-Pro Staffing</strong>" + content;
  return (
    <div className="page-about-wrapper sm-top">
      <div className="container">
        <div className="row align-items-lg-center">
          <div className="col-md-6 col-lg-7 order-1">
            <Content classes="about-content ml-0">
              <h6 style={{ color: "red" }}>{title}</h6>
              <h2 style={{ color: "red" }}>{heading}</h2>
              <p>
                <b style={{ color: "red" }}>S-Pro Staffing</b>
                {content}
              </p>
            </Content>
          </div>

          <div className="col-md-6 col-lg-5 order-0 order-md-1">
            <Thumbnail classes="about-thumb" imgSrc={thumb} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
