import React, { Fragment, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { LOGIN } from "../../helpers/graphQL/Queries";
import { useHistory } from "react-router-dom";
import socialNetworks from "../../data/SocialNetworks/socials";

const LoginRegister = () => {
  const history = useHistory();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [loginHandler, { data, loading, error }] = useLazyQuery(LOGIN, {
    onCompleted(data) {
      if (data.signin === null) {
        alert("Invalid Credentials.");
      } else {
        localStorage.setItem("token", data.signin);
        // alert("Welcome to the admin panel.")
        history.push("/admin/dashboard");
      }
    },
    onError(error) {
      console.log(error);
    },
  });

  const submitHandler = (e) => {
    e.preventDefault();
    loginHandler({
      variables: { email: formData.email, password: formData.password },
    });
  };

  return (
    <Fragment>
      <div className="off-canvas-item">
        <div className="log-in-content-wrap">
          <h2>Login</h2>
          <div className="login-form mtn-15">
            <form>
              <div className="form-input-item">
                <label htmlFor="username" className="sr-only">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  required
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>

              <div className="form-input-item">
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  required
                  value={formData.password}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>

              <div className="form-input-item">
                <button className="btn-submit" onClick={submitHandler}>
                  Login
                </button>
              </div>
            </form>
          </div>

          {/* <div className="sign-up-notification">
            <p>
              Not Resisted? <a href="/">Create Account Now.</a>
            </p>
          </div> */}
        </div>
      </div>

      <div className="off-canvas-item mt-sm-30">
        <div className="social-icons">
          {socialNetworks.map((social) => (
            <a
              key={social.id}
              href={`https://${social.networkName}.com/${social.username}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className={`fa fa-${social.networkName}`} />
            </a>
          ))}
        </div>
        <div className="copyright-content">
          <p>
            {" "}
            © S-Pro Staffing {new Date().getFullYear()} All Right Reserved.
          </p>
        </div>
      </div>
    </Fragment>
  );
};

export default LoginRegister;
