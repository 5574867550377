import React, { useState, useEffect, Fragment } from "react";
import PageWrapper from "../../components/PageWrapper";
import PageHeader from "../../components/PageHeader/joblistindex";
import Jobsearch from "../../components/PageHeader/jobsearch";
import Jobdetails from "../../components/PageHeader/jobdetails";
import BlogContent from "./BlogContent";
import SidebarForBlog from "../SidebarForBlog";
import { Link, useHistory, useLocation } from "react-router-dom";
import ReactSelect from "react-select";
import { useLazyQuery } from "@apollo/client";
import {
  GET_LOCATION_DROPDOWN,
  JOBS_BY_JOB_TITLE_LOCATION_DISTANCE_JOB_SECTOR,
} from "../../helpers/graphQL/Queries";

const BlogPage = ({ sidebar_position, blog_type, sidebar }) => {
  //Search
  const options = [];
  const history = useHistory();
  const search = useLocation().search;
  const titleURL = new URLSearchParams(search).get("title");
  const locationURL = new URLSearchParams(search).get("location");
  const rangeURL = new URLSearchParams(search).get("range");

  const [titleState, setTitleState] = useState(!titleURL ? "" : titleURL);
  const [locationState, setLocationState] = useState(
    !locationURL ? "" : locationURL
  );
  const [rangeState, setRangeState] = useState(rangeURL);
  const [jobSectorState, setJobSectorState] = useState("");
  const [rangeChangeState, setRangeChangeState] = useState(false);

  console.log({ titleState, locationState, rangeState, jobSectorState });

  const [jobByJobInfoFunction, jobByJobInfo] = useLazyQuery(
    JOBS_BY_JOB_TITLE_LOCATION_DISTANCE_JOB_SECTOR,
    {
      variables: {
        jobTitle: titleState,
        location: locationState,
        distance: parseInt(rangeState),
        jobSector: jobSectorState,
        pollInterval: 3000,
      },
    }
  );

  console.log(
    jobByJobInfo?.data?.SearchAgencyPostedJobByJobTitleLocationDistanceJobsector
  );

  useEffect(() => {
    jobByJobInfoFunction();
    // history.push('/Job_list')
    if (rangeChangeState) {
      let titles = new URLSearchParams(window.location.search);
      titles.set("range", "");
      setRangeState("");
      titles.set("location", "");
      titles.set("title", "");
      history.push(window.location.pathname + "?" + titles.toString());
      console.log({ titles });
    }
    setRangeChangeState(true);
  }, [titleState, locationState, rangeState, jobSectorState]);

  //Location
  const [getLocationDropdownFunc, getLocationDropdown] = useLazyQuery(
    GET_LOCATION_DROPDOWN,
    {
      variables: { address: locationState },
    }
  );

  useEffect(() => {
    getLocationDropdownFunc();
  }, []);

  const updateDropDownFunc = () => {
    return getLocationDropdown?.data?.addressList.map((row, key) =>
      options.push({ value: row, label: row })
    );
  };
  updateDropDownFunc();

  return (
    <Fragment>
      <PageHeader
        bgImg={require("../../assets/img/backgroundImages/job-search-banner.jpg")}
        title={
          window.location.pathname == "/Search-jobs"
            ? "Search Jobs"
            : "Job Details"
        }
        content={""}
      />
      {window.location.pathname == "/Search-jobs" ? (
        <>
          <div className="col-md-12">
            {/* <form className="input-form"> */}
            <div className="container">
              <div className="row" id="searchjob">
                <div className="col-md-6">
                  <input
                    className="input-Text"
                    type="text"
                    style={{
                      width: "100%",
                      height: "50px",
                      marginBottom: "10px",
                      paddingLeft: "10px",
                    }}
                    placeholder="Job title or keywords"
                    value={titleState}
                    onChange={(e) => setTitleState(e.target.value)}
                  />
                </div>

                <div className="col-md-6">
                  {/* <input
                      type="text"
                      style={{
                        width: "100%",
                        height: "50px",
                        marginBottom: "10px",
                        paddingLeft: "10px",
                      }}
                      placeholder="Location or postal code"
                    /> */}
                  <ReactSelect
                    options={options}
                    inputValue={locationState}
                    onInputChange={(value) => setLocationState(value)}
                    onChange={(value) => setLocationState(value.value)}
                    placeholder="Location or postal code"
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        margin: 0,
                        padding: 0,
                        height: 50,
                        // border: "1px solid rgba(119, 119, 119, 0.2)",
                        fontWeight: "normal",
                      }),
                    }}
                  />
                </div>
              </div>
            </div>
            {/* </form> */}
          </div>

          <div
            class="ttm-row sidebar job-sidebar clearfix"
            style={{ padding: "60px 0" }}
          >
            <div class="container">
              <div class="row">
                <div class="col-lg-5 widget-area sidebar-left job_list-widget-area">
                  <div class="job_list-widget">
                    <aside
                      class="widget job-widget"
                      onChange={(e) => setJobSectorState(e.target.value)}
                    >
                      <h3 class="widget-title">
                        <b style={{ color: "red" }}>Job Categories</b>
                      </h3>
                      <form
                        id="list1"
                        class="list-filter"
                        style={{
                          borderRadius: "6px",
                          display: "block",
                        }}
                      >
                        <div
                          style={{
                            display: "block",
                            position: "relative",
                            boxSizing: "borderBox",
                          }}
                        >
                          <label class="radio">
                            <input
                              type="radio"
                              name="radio"
                              value="Administrative support"
                            />{" "}
                            Administrative support
                          </label>
                          <label class="radio">
                            <input
                              type="radio"
                              name="radio"
                              value="Contact centre and customer care"
                            />{" "}
                            Contact centre and customer care
                          </label>
                          <label class="radio">
                            <input
                              type="radio"
                              name="radio"
                              value="Engineering"
                            />{" "}
                            Engineering
                          </label>
                          <label class="radio">
                            <input
                              type="radio"
                              name="radio"
                              value="Finance and accounting"
                            />{" "}
                            Finance and accounting
                          </label>
                          <label class="radio">
                            <input
                              type="radio"
                              name="radio"
                              value="Healthcare"
                            />{" "}
                            Healthcare
                          </label>
                          <label class="radio">
                            <input
                              type="radio"
                              name="radio"
                              value="Human resources"
                            />{" "}
                            Human resources
                          </label>
                          <label class="radio">
                            <input
                              type="radio"
                              name="radio"
                              value="Industrial support"
                            />{" "}
                            Industrial support
                          </label>
                          <label class="radio">
                            <input
                              type="radio"
                              name="radio"
                              value="Sales and marketing"
                            />{" "}
                            Sales and marketing
                          </label>
                          <label class="radio">
                            <input
                              type="radio"
                              name="radio"
                              value="Skilled trades and industrial management"
                            />{" "}
                            Skilled trades and industrial management
                          </label>
                          <label class="radio">
                            <input
                              type="radio"
                              name="radio"
                              value="Technologies"
                            />{" "}
                            Technologies
                          </label>
                          <label class="radio">
                            <input type="radio" name="radio" value="Legal" />{" "}
                            Legal
                          </label>
                          <label class="radio">
                            <input
                              type="radio"
                              name="radio"
                              value="Manufacturing"
                            />{" "}
                            Manufacturing
                          </label>
                          <label class="radio">
                            <input type="radio" name="radio" value="" /> Clear
                            Filter
                          </label>
                        </div>
                      </form>
                    </aside>
                  </div>
                </div>
                <div class="col-lg-7 content-area">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="showing-result-block d-sm-flex align-items-center justify-content-between mb-3">
                        <span class="showing-result" style={{ color: "red" }}>
                          Jobs Search Results :
                        </span>
                      </div>
                    </div>
                  </div>

                  <div>
                    {jobByJobInfo?.loading ? (
                      <div class="d-flex justify-content-center">
                        <div class="spinner-border text-danger" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : !jobByJobInfo?.data
                        ?.SearchAgencyPostedJobByJobTitleLocationDistanceJobsector ||
                      jobByJobInfo?.data
                        ?.SearchAgencyPostedJobByJobTitleLocationDistanceJobsector
                        .length === 0 ? (
                      <center>No search results.</center>
                    ) : (
                      jobByJobInfo?.data?.SearchAgencyPostedJobByJobTitleLocationDistanceJobsector.map(
                        (row) => (
                          <div
                            class="showing-result-block-card"
                            key={row.agency_job_post_job_id}
                          >
                            <Link
                              to={`Job-details/${row.agency_job_post_job_id}`}
                            >
                              <div>
                                <h4 className="red-clr">{row.job_title}</h4>
                                <p>{row.job_description}</p>
                                <hr></hr>
                                <span>
                                  <i class="fa fa-map-marker red-clr"></i>{" "}
                                  {row.location}
                                </span>{" "}
                                &nbsp;
                                <span>
                                  <i className="fa fa-filter red-clr"></i>{" "}
                                  {row.job_sector}
                                </span>
                              </div>
                            </Link>
                          </div>
                        )
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Jobdetails></Jobdetails>
      )}
    </Fragment>
  );
};

export default BlogPage;
