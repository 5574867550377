import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { MdChat, MdCheckCircle, MdPeople, MdPerson, MdSubtitles, MdLogout, MdHelpOutline, MdHighlightOff, MdArrowBack, MdDashboard } from "react-icons/md";
import { BsPersonLinesFill } from "react-icons/bs";
const Sidebar = () => {
    const history = useHistory()

    const clickHandler = () => {
        localStorage.clear("token")
        history.push('/')
    }
    return (
        <div className="bg-dark col-md-3 col-lg-2 sidebar-offcanvas pl-0" id="sidebar" role="navigation" style={{marginTop: 15, minHeight: '100vh' }}>
            <ul className="nav flex-column sticky-top pl-0 pt-5 mt-5 ">
                <li className="nav-item mb-2"><Link className="nav-link text-light" to="/admin/dashboard"><MdDashboard /> <span className="ml-3"> Dashboard</span></Link></li>
                <li className="nav-item mb-2 "><Link className="nav-link text-light" to="/admin/employer-applications"><MdPeople /> <span className="ml-3"> Employer Applications</span></Link></li>
                <li className="nav-item mb-2 "><Link className="nav-link text-light" to="/admin/job-post"><MdSubtitles /><span className="ml-3"> Job Post</span></Link></li>
                <li className="nav-item mb-2 "><Link className="nav-link text-light" to="/admin/posted-jobs"><MdCheckCircle /><span className="ml-3"> Posted Jobs</span></Link></li>
                <li className="nav-item mb-2 "><Link className="nav-link text-light" to="/admin/candidate-applications"><BsPersonLinesFill /><span className="ml-3"> Candidate Applications</span></Link></li>
                <li className="nav-item mb-2 "><Link className="nav-link text-light" to="/admin/job-seeker-applications"><MdPerson /><span className="ml-3"> Job Seeker Applications</span></Link></li>
                <li className="nav-item mb-2 "><Link className="nav-link text-light" to="#" onClick={clickHandler}><MdArrowBack /> <span className="ml-3"> Logout</span></Link></li>
            </ul>
        </div>
    )
}

export default Sidebar
