import Spinner from "react-bootstrap/Spinner";
import React from "react";
function LoadingSpinner() {
  return (
    <div class="container">
      <div class="row">
        <div class="col-lg-10 col-xl-8 m-auto text-center">
          <div class="page-header-content-inner">
            <div class="page-header-content">
              <br />
              <br />
              <Spinner animation="border" role="status">
                {
                  // <span className="visually-hidden">Loading...</span>
                }
              </Spinner>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoadingSpinner;
