import React, { Fragment } from "react";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import About from "../components/About/home-two";
import Funfact from "../components/Funfact";

import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
import funfactbg from "../assets/img/fun-fact-bg.jpg";

import ServiceThumb from "../assets/img/about.jpg";
import { Link } from "react-router-dom";

const PageAbout = () => {
  return (
    <Fragment>
      <Header />
      <PageHeader
        bgImg={require("../assets/img/header-img1.png")}
        title="ABOUT US"
        content="S-Pro Staffing uses professional expertise to help employers recruit effectively. We help our clients find the best talent fast and select the right fit for their business needs using the most advanced search and selection tools and methods. The quality of our candidates is out- standing. We guarantee that we are able to identify and deliver to our clients the best talent available on the market today."
      />
      <About
        title={"Our Team"}
        heading="Meet Our <br/> Expert Member"
        thumb={ServiceThumb}
        content="<b>S-Pro Staffing</b> always try to provide the best Business Solutions for Clinets to grow up their Business very sharply and smoothly. We voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt."
      />

      <div
        className="fun-fact-area sm-top parallax"
        style={{ backgroundImage: `url(${funfactbg})` }}
      >
        <div className="container">
          <div className="row mb-5">
            <div className="col-lg-8 m-auto text-center">
              <h4 className="white-clr">
                There are many reasons why you should choose our staffing agency
                for your hiring needs. Here are some of the top reasons.
              </h4>
            </div>
          </div>
          <div className="row ">
            <div className={"col-md-6 col-lg-6"}>
              <div className="blog-item">
                <div className="blog-content">
                  <h2
                    className="h5"
                    style={{
                      color: "red",
                      backgroundColor: "red",
                      padding: 15,
                      color: "white",
                    }}
                  >
                    {"Expertise"}
                  </h2>
                  <p style={{ height: 140 }}>
                    {
                      "We recruit the highest quality candidates to ensure that the candidate brings the most value to your job. We have many candidates in our pool who can qualify for the job. We let you choose the best candidate to fit your business needs."
                    }
                  </p>
                </div>
              </div>
            </div>
            <div className={"col-md-6 col-lg-6"}>
              <div className="blog-item">
                <div className="blog-content">
                  <h2
                    className="h5"
                    style={{
                      color: "red",
                      backgroundColor: "red",
                      padding: 15,
                      color: "white",
                    }}
                  >
                    {"Personalized Attention "}
                  </h2>
                  <p style={{ height: 140 }}>
                    {
                      "We specialize in providing staffing services in vari- ous domains; our screening process is tailor-made to provide our clients with suitable candidates. Our goal is to make sure that our clients and candi- dates are a perfect fit for each other."
                    }
                  </p>
                </div>
              </div>
            </div>
            <div className={"col-md-6 col-lg-6"}>
              <div className="blog-item">
                <div className="blog-content">
                  <h2
                    className="h5"
                    style={{
                      color: "red",
                      backgroundColor: "red",
                      padding: 15,
                      color: "white",
                    }}
                  >
                    {"Extensive Network "}
                  </h2>
                  <p style={{ height: 140 }}>
                    {
                      "S Pro Staffing follows a systematic process for screening candidates before the job interview. This screening is done based on the work requirements. We screen our candidates thoroughly and guaran- tee that every individual is qualified to work for your company. The steps involved are skill assessment, WHMIS awareness test, CMP compliance training, background and criminal record check."
                    }
                  </p>
                </div>
              </div>
            </div>
            <div className={"col-md-6 col-lg-6"}>
              <div className="blog-item">
                <div className="blog-content">
                  <h2
                    className="h5"
                    style={{
                      color: "red",
                      backgroundColor: "red",
                      padding: 15,
                      color: "white",
                    }}
                  >
                    {"Quality Candidates "}
                  </h2>
                  <p style={{ height: 140 }}>
                    {
                      "Our rates are competitive in the industry. We are renowned for our exceptional service in Ontario without burning a hole in your pocket. You can save your valuable resources allowing you to focus on your business goals."
                    }
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <div className={"col-md-8 col-lg-6 m-auto "}>
              <div className="blog-item">
                <div className="blog-content">
                  <h2
                    className="h5"
                    style={{
                      color: "red",
                      backgroundColor: "red",
                      padding: 15,
                      color: "white",
                    }}
                  >
                    {"Flexibility"}
                  </h2>
                  <p style={{ height: 140 }}>
                    {
                      "We offer a variety of staffing solutions to meet your needs, whether you need temporary, temp-to-perm, or direct hire staffing. We work with you to find the best solution that fits your budget and timeline."
                    }
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-lg-12 m-auto">
              <h5 className="white-clr">
                Overall, SPro staffing agency is committed to providing you with
                exceptional service and finding the right talent to help your
                organization succeed.{" "}
              </h5>
            </div>
          </div>
        </div>
      </div>

      {/* <Services /> */}

      <div className={`feature-area-wrapper sm-top`}>
        <div className="container">
          <div className="row mb-5">
            <div className="col-lg-8 m-auto text-center">
              <h4 className="red-clr-b mb-3">{"AREAS OF EXPERTISE"}</h4>
              <h3>
                Using professional expertise to help employers recruit
                effectively.
              </h3>
            </div>
          </div>
          <div className="row mtn-sm-60 mtn-md-5">
            <div className="col-md-4 mb-5 mt-5">
              <div className="icon-box-item">
                <div className="icon-box__icon">
                  <img
                    src={require("../assets/img/feature/WAREHOUSE-LOGISTICS.png")}
                    alt="S-Pro Staffing-Feature"
                  />
                </div>
                <div>
                  <h5>WAREHOUSE / LOGISTICS</h5>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-5 mt-5">
              <div className="icon-box-item">
                <div className="icon-box__icon">
                  <img
                    src={require("../assets/img/feature/AUTO-MANUFACTURING.png")}
                    alt="S-Pro Staffing-Feature"
                  />
                </div>
                <div>
                  <h5>AUTO MANUFACTURING</h5>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-5 mt-5">
              <div className="icon-box-item">
                <div className="icon-box__icon">
                  <img
                    src={require("../assets/img/feature/DISTRIBUTION-CENTRES.png")}
                    alt="S-Pro Staffing-Feature"
                  />
                </div>
                <div>
                  <h5>DISTRIBUTION CENTRES</h5>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="icon-box-item">
                <div className="icon-box__icon">
                  <img
                    src={require("../assets/img/feature/FOOD-&-BEVERAGES.png")}
                    alt="S-Pro Staffing-Feature"
                  />
                </div>
                <div>
                  <h5>FOOD & BEVERAGES</h5>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="icon-box-item">
                <div className="icon-box__icon">
                  <img
                    src={require("../assets/img/feature/PRODUCTION-FACILITIES.png")}
                    alt="S-Pro Staffing-Feature"
                  />
                </div>
                <div>
                  <h5>PRODUCTION FACILITIES</h5>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-5">
              <div className="icon-box-item">
                <div className="icon-box__icon">
                  <img
                    src={require("../assets/img/feature/LABOUR-&-CONSTRUCTION.png")}
                    alt="S-Pro Staffing-Feature"
                  />
                </div>
                <div>
                  <h5>LABOUR & CONSTRUCTION</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Features classes={"sm-top"} /> */}
      {/* <Testimonial /> */}
      {/* <Team /> */}

      <div className="container sm-top">
        <div className="row mb-5">
          <div className="col-lg-8 m-auto text-center">
            <h4 className="red-clr-b mb-3">{"OUR SPECIALIZATIONS"}</h4>
            <h3>
              Specialized in different departments and using that experience to
              hire the perfect candidates.
            </h3>
          </div>
        </div>
        <div className="row mtn-40">
          <div className={"col-md-6 col-lg-3"}>
            <div className="blog-item">
              <figure className="blog-thumb">
                <img
                  src={require("../assets/img/feature/AUTOMOTIVE_IMG.png")}
                />
              </figure>
              <div className="blog-content">
                <h2 className="h5" style={{ color: "red" }}>
                  {"AUTOMOTIVE"}
                </h2>
                <p style={{ height: 380 }}>
                  » GENERAL WORKERS <br></br>» FORKLIFT OPERATORS<br></br>»
                  PRODUCTION OPERATORS<br></br>» MACHINISTS<br></br>» ASSEMBLERS
                  <br></br>» INSTALLERS<br></br>» STUFFING/DE-STUFFING<br></br>»
                  INVENTORY CLERKS<br></br>» TEAM LEADERS<br></br>»
                  MERCHANDISERS<br></br>» WAREHOUSE CLERKS<br></br>»
                  SHIPPER/RECEIVER<br></br>» MILLWRIGHTS<br></br>
                </p>
              </div>
            </div>
          </div>
          <div className={"col-md-6 col-lg-3"}>
            <div className="blog-item">
              <figure className="blog-thumb">
                <img src={require("../assets/img/feature/TRANSPORT_IMG.png")} />
              </figure>
              <div className="blog-content">
                <h2 className="h5" style={{ color: "red" }}>
                  {"TRANSPORT"}
                </h2>
                <p style={{ height: 380 }}>
                  » CLASS 1 (AZ) DRIVER <br></br>» CLASS 2 (BZ) DRIVER <br></br>
                  » CLASS 3 (DZ) DRIVER <br></br>» CLASS 4B (E) DRIVER <br></br>
                  » CLASS 5 (G) DRIVER <br></br>» REGIONAL SHORT <br></br>» HAUL
                  DELIVERIES <br></br>» NATIONAL LONG <br></br>» HAUL DELIVERIES{" "}
                  <br></br>» DISPATCHERS <br></br>» US DELIVERIES
                </p>
              </div>
            </div>
          </div>
          <div className={"col-md-6 col-lg-3"}>
            <div className="blog-item">
              <figure className="blog-thumb">
                <img src={require("../assets/img/feature/FOOD_IMG.png")} />
              </figure>
              <div className="blog-content">
                <h2 className="h5" style={{ color: "red" }}>
                  {"FOOD"}
                </h2>
                <p style={{ height: 380 }}>
                  » Food Packagers <br></br>» Line Operators <br></br>»
                  Production Workers <br></br>» Team Assembly <br></br>» Bakers{" "}
                  <br></br>» Sorters <br></br>» Material Lander <br></br>» Order
                  Picker <br></br>» On Loader <br></br>» Off Loader <br></br>»
                  Sanitation Labour <br></br>» Heavy Lifters <br></br>» Mixers{" "}
                  <br></br>» Quality Control <br></br>» Food Delivery Drivers{" "}
                  <br></br>
                </p>
              </div>
            </div>
          </div>
          <div className={"col-md-6 col-lg-3"}>
            <div className="blog-item">
              <figure className="blog-thumb">
                <img
                  src={require("../assets/img/feature/MANAGEMENT_IMG.png")}
                />
              </figure>
              <div className="blog-content">
                <h2 className="h5" style={{ color: "red" }}>
                  {"MANAGEMENT"}
                </h2>
                <p style={{ height: 380 }}>
                  » PROJECT MANAGERS <br></br>» PROJECT COORDINATORS <br></br>»
                  FLEET MANAGERS <br></br>» SALES REPRESENTATIVES <br></br>»
                  ACCOUNT DIRECTORS <br></br>» BUSINESS DEVELOPMENT <br></br>»
                  PROFESSIONALS <br></br>» ACCOUNTANTS <br></br>» CUSTOMER
                  SERVICE <br></br>» FINANCIAL MANAGERS <br></br>» ACCOUNTANTS{" "}
                  <br></br>» BUSINESS ANALYSTS <br></br>» MARKETING MANAGERS{" "}
                  <br></br>» IT. MANAGERS <br></br>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*<BrandLogo />*/}

      <Funfact />

      {/* <CallToAction /> */}

      <Footer />
      <LoginRegister />
      <MobileMenu />
    </Fragment>
  );
};

export default PageAbout;
