import React, { Fragment } from "react";
import Header from "../components/Header";
import Funfact from "../components/Funfact";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";

import PageHeader from "../components/PageHeader";
import ourprocess from "../assets/img/ourProcess/ourprocess.png";
import Additional from "../assets/img/ourProcess/Additionalbenefits.png";
import { Link } from "react-router-dom";
const Ourprocess = () => {
  return (
    <Fragment>
      <Header />

      <PageHeader
        bgImg={require("../assets/img/backgroundImages/our-process-banner.jpg")}
        title="SELECTION & STAFFING PROCESS"
      />

      <div className="container">
        <div className="row">
          <div style={{ padding: "60px" }} className="col-lg-6">
            <h2 style={{ color: "red" }}>SELECTION & STAFFING PROCESS</h2>
            <blockquote
              class="blockquote"
              style={{
                padding: "25px 0px 25px",
              }}
            >
              <p
                style={{
                  textAlign: "left",
                  fontSize: "0.9375rem",
                }}
              >
                The screening and interviewing process is a crucial step in the
                staffing and recruiting process. It involves evaluating
                potential candidates to determine their suitability for a
                specific job or role. The screening and interviewing process
                typically consists of the following steps:{" "}
              </p>
            </blockquote>

            <p
              style={{
                padding: "3px",
              }}
            >
              <strong style={{ color: "red" }}>Resume screening: </strong>
              Reviewing resumes and cover letters to identify qualified
              candidates who meet the minimum requirements for the job.
            </p>

            <p>
              <strong style={{ color: "red" }}>Initial phone screening:</strong>{" "}
              Conducting a preliminary phone interview to further evaluate a
              candidate’s qualifications, skills, and fit for the role.
            </p>

            <p>
              <strong style={{ color: "red" }}>In-person interview:</strong>{" "}
              Inviting the most promising candidates for an in-person interview
              with a hiring manager or team.
            </p>
          </div>
          <div className="col-lg-6">
            <img
              style={{
                padding: "25px 0px 25px",
                  
                marginLeft: "-70px",
                maxWidth: "135%",
              }}
              src={ourprocess}
              alt="BigCo Inc. logo"
            />
          </div>

          <div style={{ paddingLeft: "60px" }} className="col-lg-6">
            <h2 style={{ color: "red" }}>ADDITIONAL BENEFITS</h2>

            <ul
              style={{
                textAlign: "left",
                fontSize: "0.9375rem",
                padding: "3px",
                lineHeight: "1.6",
              }}
            >
              <li>
                {" "}
                <strong style={{ color: "red", fontSize: "16px" }}>
                  »{" "}
                </strong>{" "}
                No additional cost for running the payroll
              </li>{" "}
              <li>
                {" "}
                <strong style={{ color: "red", fontSize: "16px" }}>
                  »{" "}
                </strong>{" "}
                No advertisement cost
              </li>
              <li>
                {" "}
                <strong style={{ color: "red", fontSize: "16px" }}>
                  »{" "}
                </strong>{" "}
                We provide any additional training upon the client’s request{" "}
              </li>
              <li>
                {" "}
                <strong style={{ color: "red", fontSize: "16px" }}>
                  »{" "}
                </strong>{" "}
                Layoffs and recalls of the candidates upon the client’s request{" "}
              </li>
              <li>
                {" "}
                <strong style={{ color: "red", fontSize: "16px" }}>
                  »{" "}
                </strong>{" "}
                We follow wsib guidelines to cover injuries at work
              </li>{" "}
              <li>
                {" "}
                <strong style={{ color: "red", fontSize: "16px" }}>
                  »{" "}
                </strong>{" "}
                We save customers valuable time by searching for the right
                candidates
              </li>
              <li>
                <strong style={{ color: "red", fontSize: "16px" }}>» </strong>{" "}
                Customer service is available 24 hours and 7 days a week
              </li>
              <li>
                {" "}
                <strong style={{ color: "red", fontSize: "16px" }}>
                  »{" "}
                </strong>{" "}
                Provide flexible and reliable staffing to meet changing business
                needs
              </li>
              <li>
                {" "}
                <strong style={{ color: "red", fontSize: "16px" }}>
                  »{" "}
                </strong>{" "}
                We cover statutory requirements, workplace safty insurance board
                (wsib), canada pension plan (cpp), employment insurance (ei),
                vacation pay, t-4 sli
              </li>
            </ul>
          </div>
        
          <div className="col-lg-6">
            <img
              style={{
                padding: "25px 0px 25px",
                maxwidth: "80%",
                maxHeight: "90%",
                padding: "25px",
              }}
              src={Additional}
              alt="BigCo Inc. logo"
            />
          </div>
        </div>
      </div>
      <Funfact classes="sp-top" />
      <Footer />
      <LoginRegister />
      <MobileMenu />
    </Fragment>
  );
};

export default Ourprocess;
