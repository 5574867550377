import React from "react";
import DashboardMain from '../../pages/Admin/DashboardMain'

function HeaderConfig(props) {
  const LoginRegHandler = () => {
      const offCanvasConfig = document.querySelector(".off-canvas-cog");
      offCanvasConfig.classList.add("active");
      document.querySelector("body").classList.add("fix");
  };

  const MobileMenuHandler = () => {
    const offCanvasMenu = document.querySelector(".off-canvas-menu");
    offCanvasMenu.classList.add("active");
  };

  return (
    <div className="header-action mt-lg-1 text-right">
      <button
        onClick={LoginRegHandler}
        style={{
          fontSize: "15px",
          color: "#FFFFFF",
          lineHeight: 1,
          letterSpacing: 1.62,
          textTransform: "uppercase",

          // only screen and (min-width: 992px) and (max-width: 1199.98px)?
        }}
      >
        Sign-in
      </button>
      <button onClick={MobileMenuHandler} className="btn-menu d-lg-none">
        <i className="fa fa-bars" />
      </button>
    </div>
  );
}

export default HeaderConfig;
