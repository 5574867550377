import React from "react";
import SectionTitle from "../../UI/SectionTitle";
// import SlickSlider from "../../UI/Slick";
import Member from "./Member";

import teamBg from "../../../assets/img/backgroundImages/Creative-team-image.jpg";
// import Teams from "../../../data/Team/home-one";

function Team() {
  const settings = {
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: false,
    autoplay: false,
    dots: true,
    className: "team-content-wrap slick-dots--light mtn-md-5",
    responsive: [
      {
        breakpoint: 1550,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div
      className="team-area-wrapper bg-img sp-y"
      style={{ backgroundImage: `url(${teamBg})` }}
    >
      <div className="container-fluid">
        <div className="row align-items-center">
          {
            //new changes
          }
          <div className="col-lg-12 ">
            <div className="row mb-5">
              <div className={"col-md-8 col-lg-6 m-auto "}>
                <SectionTitle
                  variant="light"
                  title="Our Creative Team"
                  heading="We have a great creative team to support your objective"
                  text=" is continuously trying to provide the best business solution for our clients so that they can grow their business accomplishments smoothly.
we always try to build an interactive dialog with our customers to understand their goals and help them batter throw our advice."
                />
              </div>
            </div>
          </div>

          {
            //   <div className="col-lg-8">
            //   <SlickSlider settings={settings}>
            //     {Teams.map((team) => (
            //       <div key={team.id}>
            //         <Member
            //           id={team.id}
            //           name={team.name}
            //           designation={team.designation}
            //           profilePic={team.profilePic}
            //         />
            //       </div>
            //     ))}
            //   </SlickSlider>
            // </div>
          }
        </div>
      </div>
    </div>
  );
}

export default Team;
