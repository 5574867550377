import React, { Component } from "react";
import logo from "../../assets/img/logo.svg";

class Logo extends Component {
  render() {
    return (
      <div className="logo-area" style={{ maxWidth: 100 }}>
        <a href="/">
          <img src={logo} alt="S-Pro Staffing-Logo" />
        </a>
      </div>
    );
  }
}

export default Logo;
