import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

/*
 * @ All pages Import
 */
// import Demo from "./pages/Demo";
import HomeOne from "./pages/HomeOne";
// import HomeTwo from "./pages/HomeTwo";
import Service from "./pages/Service";
import ServiceDetails from "./pages/ServiceDetails";
import BlogGridRightSidebar from "./pages/BlogGridRightSidebar";
import BlogGridLeftSidebar from "./pages/BlogGridLeftSidebar";
import BlogGridWithoutSidebar from "./pages/BlogGridWithoutSidebar";
import BlogListLeftSidebar from "./pages/BlogListLeftSidebar";
import BlogListRightSidebar from "./pages/BlogListRightSidebar";
import BlogDetailsPage from "./pages/BlogDetails";
import Team from "./pages/Team";
import TeamDetails from "./pages/TeamDetails";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Error404 from "./pages/Error404";
import SubmitResume from "./pages/SubmitResume";
import SearchJobs from "./pages/SearchJobs";
import ScrollToTop from "./helpers/ScrollToTop";
import Ourprocess from "./pages/Ourprocess";
import EmployerFaQs from "./pages/EmployerFaQs";

import HiringForm from "./pages/HiringForm";

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  concat,
  from,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";

import JobDetails from "./pages/JobDetails";
import DashboardMain from "./pages/Admin/DashboardMain";
import JobSeekerApplications from "./pages/Admin/JobSeekerApplications";
import CandidateApplications from "./pages/Admin/CandidateApplications";
import EmployerApplications from "./pages/Admin/EmployerApplications";
import PostedJobs from "./pages/Admin/PostedJobs";
import JobPost from "./pages/Admin/JobPost";

const App = () => {
  //Apollo client setup
  const uploadLink = createUploadLink({
    uri: "https://haexud92rg.execute-api.ca-central-1.amazonaws.com/backend/graphql",
    headers: {
      authorization: localStorage.getItem("token") || null,
    },
  });
  const errorLink = onError(({ graphqlErrors, nerworkError }) => {
    if (nerworkError) {
      console.log(`Network error ${nerworkError}`);
    }
    if (graphqlErrors) {
      graphqlErrors.map(({ message, location, path }) => {
        console.log(`GraphQL error ${message}`);
      });
    }
  });
  const link = from([errorLink, uploadLink]);

  const client = new ApolloClient({ cache: new InMemoryCache(), link });

  return (
    <ApolloProvider client={client}>
      <Router>
        <ScrollToTop>
          <Switch>
            <Route exact path={`${"/"}`} component={HomeOne} />
            <Route exact path={`${"/services"}`} component={Service} />
            <Route
              path={`${"/service/:serviceID"}`}
              component={ServiceDetails}
            />
            <Route
              exact
              path={`${"/Submit-resume"}`}
              component={SubmitResume}
            />
            <Route exact path={`${"/Search-jobs"}`} component={SearchJobs} />
            <Route
              exact
              path={`${"/Job-details/:jobID"}`}

              component={JobDetails}
            />

          {/* <Route
            exact
            path={`${"/blog-grid-left-sidebar"}`}
            component={BlogGridLeftSidebar}
          />
          <Route
            exact
            path={`${"/blog-grid-without-sidebar"}`}
            component={BlogGridWithoutSidebar}
          />
          <Route
            exact
            path={`${"/blog-list-left-sidebar"}`}
            component={BlogListLeftSidebar}
          />
          <Route
            exact
            path={`${"/blog-list-right-sidebar"}`}
            component={BlogListRightSidebar}
          />
          <Route path={`${"/blog/:blogID"}`} component={BlogDetailsPage} />
          <Route exact path={`${"/team"}`} component={Team} />
          <Route path={`${"/team-member/:teamID"}`} component={TeamDetails} />
     */}      <Route exact path={`${"/about"}`} component={About} />
            <Route exact path={`${"/contact"}`} component={Contact} />
            <Route
              exact
              path={`${"/selection-staffing-process"}`}
              component={Ourprocess}
            />

            <Route exact path={`${"/EmployerFAQS"}`} component={EmployerFaQs} />
            <Route exact path={`${"/Hiring-form"}`} component={HiringForm} />

            {/* Admin */}
            <Route exact path={`${"/admin/dashboard"}`} component={DashboardMain} />
            <Route exact path={`${"/admin/job-seeker-applications"}`} component={JobSeekerApplications} />
            <Route exact path={`${"/admin/candidate-applications"}`} component={CandidateApplications} />
            <Route exact path={`${"/admin/employer-applications"}`} component={EmployerApplications} />
            <Route exact path={`${"/admin/posted-jobs"}`} component={PostedJobs} />
            <Route exact path={`${"/admin/job-post"}`} component={JobPost} />


            <Route exact component={Error404} />
          </Switch>
        </ScrollToTop>
      </Router>
    </ApolloProvider>
  );
};

export default App;
