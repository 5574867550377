import { useQuery } from "@apollo/client"
import React, { useState } from "react"
import { CANDIDATES_WITHOUT_JOB_LINK_COUNT, CANDIDATES_WITH_JOB_LINK_COUNT, GET_ALL_AGENCY_JOB_POST_COUNT, GET_ALL_JOBS_COUNT } from "../../helpers/graphQL/Queries"
import { validateEmpty } from "../utils/utils"
import Navbar from "./Layout/Navbar"
import Sidebar from "./Layout/Sidebar"
import { Chart } from "react-google-charts";

const Dashboard = () => {

    const jobSeekerCount = useQuery(CANDIDATES_WITHOUT_JOB_LINK_COUNT)
    const candidateCount = useQuery(CANDIDATES_WITH_JOB_LINK_COUNT)
    const empoyerApplicationsCount = useQuery(GET_ALL_JOBS_COUNT)
    const postedJobCount = useQuery(GET_ALL_AGENCY_JOB_POST_COUNT)

    const data = [
        ["Type", "Number"],
        ["Employer Applications", empoyerApplicationsCount?.data?.TotalNumberOfJobs],
        ["Posted Jobs", postedJobCount?.data?.TotalNumberOfAgencyPostedJobs],
        ["Candidate Applications", candidateCount?.data?.TotalNumberOfCandidatesWithJobLink],
        ["Job Seeker Applications", jobSeekerCount?.data?.TotalNumberOfCandidatesWithoutJobLink],
    ]

    const options = {
        // title: "My Daily Activities",
        colors: ['#28a745', '#dc3545', '#17a2b8', '#343a40'],
        backgroundColor: '#f5f5f5'    
    }

    return (
        <>
            <Navbar />
            <div className="row m-0 p-0">
                <Sidebar />
                <div className="col" style={{ marginTop: '100px' }}>
                    <h3 className='red-clr-b' style={{ textAlign: 'center' }}>Dashboard</h3>
                    <section className="row" style={{ paddingTop: 30 }}>
                        <div className="col-8">
                            <div>
                                <Chart
                                    chartType="PieChart"
                                    data={data}
                                    options={options}
                                    width={"100%"}
                                    height={"100vh"}
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="">
                                <div className="card text-white bg-success mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title">Employer Applications</h5>
                                        <p className="card-text">{validateEmpty(empoyerApplicationsCount?.data?.TotalNumberOfJobs)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <div className="card text-white bg-danger mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title">Posted Jobs</h5>
                                        <p className="card-text">{validateEmpty(postedJobCount?.data?.TotalNumberOfAgencyPostedJobs)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <div className="card text-white bg-info mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title">Candidate Applications</h5>
                                        <p className="card-text">{validateEmpty(candidateCount?.data?.TotalNumberOfCandidatesWithJobLink)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <div className="card text-white bg-dark mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title">Job Seeker Applications</h5>
                                        <p className="card-text">{validateEmpty(jobSeekerCount?.data?.TotalNumberOfCandidatesWithoutJobLink)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                </div>
            </div>
        </>
    )
}

export default Dashboard