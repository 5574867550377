import React from "react";
import parse from "html-react-parser";
import aboutData from "../../../data/About/home-two";
import aboutThumb from "../../../assets/img/about-2-bg.jpg";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div
      className="home-two-about-area"
      style={{ backgroundImage: `url(${aboutThumb})` }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 d-lg-none">
            <figure className="about-thumb">
              <img
                src={require("../../../assets/img/" + aboutData.thumb)}
                alt="S-Pro Staffing-About"
              />
            </figure>
          </div>

          <div className="col-lg-6">
            <div className="about-content about-content--2">
              <h4 className="red-clr-b">OUR VALUES</h4>
              <h2>We Provide The Best Services For Growth & Development</h2>
              <span className="about-since">Since 2010</span>
              <p>
                <strong className="red-clr">Customer focus: </strong>
                <span>
                  Providing high-quality service and long-lasting building
                  relationships with clients and job seekers.
                </span>
                <br></br>
                <br></br>
                <strong className="red-clr">Integrity: </strong>
                <span>
                  Maintaining ethical and transparent practices in all business
                  dealings.
                </span>
                <br></br>
                <br></br>
                <strong className="red-clr">Professionalism: </strong>
                <span>
                  Demonstrating expertise, competence, and professionalism in
                  all interactions.
                </span>
                <br></br>
                <br></br>
                <strong className="red-clr">Respect: </strong>
                <span>
                  Treat clients, job seekers, and employees with dignity,
                  respect, and fairness.
                </span>
                <br></br>
                <br></br>
                <strong className="red-clr">Innovation: </strong>
                <span>
                  Staying current with industry trends and utilizing new
                  technologies and strategies to improve services.
                </span>
                <br></br>
                <br></br>
                <strong className="red-clr">Teamwork: </strong>
                <span>
                  Encouraging collaboration and teamwork to achieve common goals
                  and provide the best possible service.
                </span>
                <br></br>
                <br></br>
                <strong className="red-clr">Growth: </strong>
                <span>
                  Pursuing opportunities for growth and development for both the
                  company and its employees.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
