import { useMutation } from "@apollo/client";
import React, { useState, useEffect, Fragment } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import { APPLY_FOR_JOB_WITH_JOB_ID } from "../../helpers/graphQL/Mutations";
import LoadingSpinner from "../Spinner";
const Jobdetails = () => {
  const [data, setData] = useState(null);

  const search = useLocation().search

  const { jobID } = useParams();
  const [captchaState, setCaptchaState] = useState(false)
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [openErrorModalRecaptcha, setOpenErrorModalRecaptcha] = useState(false)
  const [openSuccessModal, setOpenSuccessModal] = useState(false)

  // const jobIDURL = new URLSearchParams(search).get("id")
  // const jobSectorURL = new URLSearchParams(search).get("jobSector")
  // console.log({ jobIDURL, jobSectorURL })


  const [ApplyJob, { datas, loading, error }] = useMutation(APPLY_FOR_JOB_WITH_JOB_ID, {
    onCompleted: data => {
      setOpenSuccessModal(!openSuccessModal)
      return
    },
    onError: error => {
      setOpenErrorModal(!openErrorModal)
      return
    }
  })

  const [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phone: '',
    city: "",
    jobSector: "",
    resume: null,
  })

  const handleFormSubmit = e => {
    e.preventDefault()
    applyJobRes()
  }
  console.log({ data, error, loading })
  console.log(formData)

  const applyJobRes = () => {
    ApplyJob({
      variables: {
        agencyJobPostJobId: parseInt(jobID),
        firstName: formData.firstName,
        middleName: formData.middleName,
        lastName: formData.lastName,
        email: formData.email,
        phoneNumber: formData.phone,
        city: formData.city,
        jobSector: formData.jobSector,
        resume: formData.resume
      }
    })
  }

  error && console.log(error?.networkError?.result?.errors)






  const id = window.location.pathname.split("/Job-details/");

  let inputvariables = {
    agencyJobPostJobId: parseInt(id[1]),
  };
  const endpoint =
    "https://haexud92rg.execute-api.ca-central-1.amazonaws.com/backend/graphql";
  const query = `query ($agencyJobPostJobId: Int!)
   {  AgencyJobPostByAgencyJobPostID(agency_job_post_job_id: $agencyJobPostJobId) { 
        agency_job_post_job_id    job_id    language    country    company_name  
          location    working_location    job_title    job_sector    job_description  
            required_qualification    start_date    start_time    start_time_zone    end_time 
               end_time_zone    pay_rate    pay_rate_currency    no_of_resources_required   
                save_as_a_template    is_deleted    deleted_at    created_at    updated_at    latitude 
                   longitude    priority    safety_shoes    safety_gloves    safety_jacket  
                     candidates {      candidate_id      agency_job_post_job_id      first_name   
                         middle_name      last_name      email      phone_number      city    
                           job_sector      resume      is_job_link      is_deleted      deleted_at 
                                created_at      updated_at          } 
                                   agencyJobPostRequirements {                  description   
                                           }    agencyJobPostskills {                
                                          description          }    job {      job_id      company_name   
                                               company_logo      company_details_phone_number      website_link    
                                                 address      city      state      country      zip_code      first_name    
                                                   middle_name      last_name      email      company_admin_contact_number  
                                                       job_title      job_type      additional_details   
                                                          no_of_resources_required      job_description_file    
                                                            is_posted      is_deleted      deleted_at      created_at   
                                                               updated_at      }    }}
`;

  useEffect(() => {
    if (Number.isInteger(parseInt(id[1]))) {
      fetch(endpoint, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          query,
          variables: inputvariables,
        }),
      })
        .then((response) => response.json())
        .then((result) => {
          setData(result.data.AgencyJobPostByAgencyJobPostID);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, []);

  return (
    <>
      {data != null ? (
        <div
          class="ttm-row sidebar job-sidebar clearfix"
          style={{ padding: "60px 0" }}
        >
          <div class="container">
            <div class="row">
              <div class="col-lg-5 widget-area sidebar-left job_list-widget-area">
                <div class="job_list-widget">
                  <aside class="widget job-widget">
                    <h4
                      class="widget-title"
                      style={{ fontSize: 20, color: "red" }}
                    >
                      <i class="ti ti-files"></i> <b>Job Information</b>
                    </h4>
                    <ul>
                      <li class="sideLeftheading" style={{ color: "red" }}>
                        <b>Job Reference# </b>
                      </li>
                      <li class="sideLeftsubheading">
                        {data != null ? data.agency_job_post_job_id : "---"}
                      </li>
                      <hr></hr>
                      <li class="sideLeftheading" style={{ color: "red" }}>
                        <b>Location </b>
                      </li>
                      <li class="sideLeftsubheading">
                        {" "}
                        {data != null ? data.location : "---"}
                      </li>
                      <hr></hr>
                      <li class="sideLeftheading" style={{ color: "red" }}>
                        <b>Qualification </b>
                      </li>
                      <li class="sideLeftsubheading">
                        {" "}
                        {data != null ? data.required_qualification : "---"}
                      </li>
                      <hr></hr>
                      <li class="sideLeftheading" style={{ color: "red" }}>
                        <b>Language </b>
                      </li>
                      <li class="sideLeftsubheading">
                        {" "}
                        {data != null ? data.language : "---"}
                      </li>
                      <hr></hr>
                      <li class="sideLeftheading" style={{ color: "red" }}>
                        <b>Pay Rate </b>
                      </li>
                      <li class="sideLeftsubheading">
                        {data != null ? data.pay_rate : "---"}
                      </li>
                      <hr></hr>
                      <li class="sideLeftheading" style={{ color: "red" }}>
                        <b>Pay Rate Currency </b>
                      </li>
                      <li class="sideLeftsubheading">
                        {" "}
                        {data != null ? data.pay_rate_currency : "---"}
                      </li>
                      <hr></hr>
                      <li class="sideLeftheading" style={{ color: "red" }}>
                        <b>Required No. of Resources </b>
                      </li>
                      <li class="sideLeftsubheading">
                        {" "}
                        {data != null ? data.no_of_resources_required : "---"}
                      </li>
                    </ul>
                  </aside>
                </div>
              </div>
              <div class="col-lg-7 content-area">
                <div class="row">
                  <div class="col-md-12">
                    <div class="showing-result-block  align-items-center ">
                      <h3 style={{ fontSize: 20, color: "red" }}>
                        <b>{data != null ? data.job_title : "---"}</b>
                      </h3>
                      <hr></hr>
                      <div style={{ width: "100%" }}>
                        <span>
                          <i class="fa fa-map-marker fa_custom"> </i>{" "}
                          {data != null ? data.location : "---"}
                        </span>
                        <span>
                          {"  "}
                          <i class="fa fa-filter fa_custom"></i>
                          {"  "} {data != null ? data.job_sector : "---"}
                        </span>
                      </div>
                    </div>
                    <div
                      class="showing-result-block  align-items-center"
                      style={{ marginTop: 12 }}
                    >
                      <span style={{ color: "red" }}>Job Description</span>
                    </div>
                    <div class="desc">
                      <p className="descrip">
                        {"  "} {data != null ? data.job_description : "---"}
                      </p>
                    </div>

                    <div
                      class="showing-result-block  align-items-center "
                      style={{ marginTop: 12 }}
                    >
                      <span style={{ color: "red" }}>Skills</span>
                    </div>
                    <div class="desc">
                      <p className="descrip">
                        {data != null
                          ? data.agencyJobPostskills.map((item) => (
                            <>
                              <i
                                class="fa fa-check-square-o"
                                aria-hidden="true"
                              >
                                {" " + item.description}
                              </i>
                              <br />
                            </>
                          ))
                          : "---"}
                      </p>
                    </div>

                    <div
                      class="showing-result-block  align-items-center"
                      style={{ marginTop: 12 }}
                    >
                      <span style={{ color: "red" }}>Requirements</span>
                    </div>
                    <div class="desc">
                      <p className="descrip">
                        {data != null
                          ? data.agencyJobPostRequirements.map((item) => (
                            <>
                              <i
                                class="fa fa-check-square-o"
                                aria-hidden="true"
                              >
                                {" " + item.description}
                              </i>
                              <br />
                            </>
                          ))
                          : "---"}
                      </p>
                    </div>

                    <div
                      class="showing-result-block  align-items-center mb-4"
                      style={{ marginTop: 12 }}
                    >
                      <span style={{ color: "red" }}>Submit your resume</span>
                    </div>

                    <div class="desc">
                      <span className="">
                        <div className="contact-form-wrap">
                          <form id="contact-form">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="single-input-item">
                                  <input
                                    type='text'
                                    placeholder='First Name*'
                                    name='firstName'
                                    value={formData.firstName}
                                    onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="single-input-item">
                                  <input
                                    type='text'
                                    placeholder='Middle Name'
                                    name='middleName'
                                    value={formData.middleName}
                                    onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="single-input-item">
                                  <input
                                    type='text'
                                    placeholder='Last Name*'
                                    name='lastName'
                                    value={formData.lastName}
                                    onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="single-input-item">
                                  <input
                                    type='email'
                                    placeholder='Email*'
                                    name='email'
                                    value={formData.email}
                                    onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="single-input-item">
                                  <input
                                    type='number'
                                    placeholder='Phone'
                                    name='phone'
                                    value={formData.phone}
                                    onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="single-input-item">
                                  <input
                                    type='text'
                                    placeholder='City*'
                                    name='city'
                                    value={formData.city}
                                    onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="single-input-item">
                                  <label>
                                    <select
                                      name="jobSector"
                                      defaultValue={formData.jobSector}
                                      onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                    >
                                      <option value="">Select a Job Sector*</option>
                                      <option value="General Labour">General Labour</option>
                                      <option value="Forklift Operators">Forklift Operators</option>
                                      <option value="Assemblers">Assemblers</option>
                                      <option value="Quality Control">Quality Control</option>
                                      <option value="Shipping & Receiving">Shipping & Receiving</option>
                                      <option value="Light Industrial">Light Industrial</option>
                                      <option value="Production Line Support">Production Line Support</option>
                                      <option value="Warehouse Clerk">Warehouse Clerk</option>
                                      <option value="Office Admin">Office Admin</option>
                                      <option value="Meat Trimmers">Meat Trimmers</option>
                                      <option value="Machine Operator">Machine Operator</option>
                                      <option value="Line Lead">Line Lead</option>
                                      <option value="Janitorial">Janitorial</option>
                                      <option value="Lumpers">Lumpers</option>
                                      <option value="Heavy Lifting">Heavy Lifting</option>
                                      <option value="Receptionist">Receptionist</option>
                                      <option value="Other">Other</option>
                                    </select>
                                  </label>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                    id="customFile"
                                    name="resume"
                                    onChange={e => setFormData({ ...formData, [e.target.name]: e.target.files[0] })}
                                  />
                                  <label className="custom-file-label" htmlFor="customFile">
                                    Upload Resume
                                  </label>
                                </div>
                              </div>

                              <div className="col-12">

                                <div className="single-input-item">
                                  <button id="apply-id-btn-outline" className={'btn-outline'} onClick={handleFormSubmit}>Submit</button>
                                </div>

                                <div className="form-message" />
                              </div>
                            </div>
                          </form>
                        </div>
                      </span>

                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter1"
        centered
        show={openErrorModal}
      >
        <Modal.Header id="contained-modal-title-vcenter1">
          <h3>Message</h3>
        </Modal.Header>
        <Modal.Body>
          Please fill out all the mandatory fields.
        </Modal.Body>
        <Modal.Footer>
          <Button variant='danger' onClick={() => setOpenErrorModal(!openErrorModal)}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter1"
        centered
        show={openErrorModalRecaptcha}
      >
        <Modal.Header id="contained-modal-title-vcenter1">
          <h3>Error</h3>
        </Modal.Header>
        <Modal.Body>
          Captcha verification is required.
        </Modal.Body>
        <Modal.Footer>
          <Button variant='danger' onClick={() => setOpenErrorModalRecaptcha(!openErrorModalRecaptcha)}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter1"
        centered
        show={openSuccessModal}
      >
        <Modal.Header id="contained-modal-title-vcenter1">
          <h3>Success</h3>
        </Modal.Header>
        <Modal.Body>
          Congratulations! Your form has been submitted.
        </Modal.Body>
        <Modal.Footer>
          <Link to="/" >
            <Button variant='success'>OK</Button>
          </Link>
        </Modal.Footer>
      </Modal>
        </div>
      ) : (
        <LoadingSpinner class="page-header-content"> </LoadingSpinner>
      )}
    </>
  );
};

export default Jobdetails;
