import React from "react";
// import FunfactItem from "./funfactItem";
import parse from "html-react-parser";

import CallToActionData from "../../data/CallToAction/call-to-action";
import { Link } from "react-router-dom";
import funfactbg from "../../assets/img/fun-fact-bg.jpg";
// import Funfacts from "../../data/Funfact/funfact";

function Funfact() {
  return (
    <div
      className="fun-fact-area sm-top parallax"
      style={{ backgroundImage: `url(${funfactbg})` }}
    >
      <div className="container">
        <div className="row">
          <div className="footer-top-content">
            <div className="row align-items-center">
              <div className="col-md-8 col-lg-6">
                <h2 style={{ color: "#FFFFFF" }}>{CallToActionData.title}</h2>
                <p style={{ color: "#FFFFFF" }}>
                  <b style={{ color: "red" }}>S-Pro Staffing</b>{" "}
                  {parse(CallToActionData.text)}
                </p>
              </div>
              <div className="col-md-4 col-lg-6 text-md-right mt-sm-25">
                <Link
                  style={{
                    color: "#FFFFFF",
                    border: "2px solid #FFFFFF",
                  }}
                  to={`${CallToActionData.btnLink}`}
                  className="btn-outline align-items-center"
                  id="apply-id-btn-outline"
                >
                  {CallToActionData.btnText}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  // return (
  //   <div
  //     className="fun-fact-area sm-top parallax"
  //     style={{ backgroundImage: `url(${funfactbg})` }}
  //   >
  //     <div className="container">
  //       <div className="row mtn-40">
  //         {Funfacts.map((funfact) => (
  //           <FunfactItem
  //             key={funfact.id}
  //             counterNumber={funfact.counterNumber}
  //             counterText={funfact.counterText}
  //           />
  //         ))}
  //       </div>
  //     </div>
  //   </div>
  // );
}

export default Funfact;
