import React, { Fragment, useState } from "react";
import Header from "../components/Header";

import Funfact from "../components/Funfact";

import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
import PageHeader from "../components/PageHeader";
import { useMutation } from "@apollo/client";
import { APPLY_FOR_JOB_WITHOUT_JOB_ID } from "../helpers/graphQL/Mutations";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
const SubmitResume = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phone: "",
    city: "",
    jobSector: "",
    resume: null,
  });

  const [captchaState, setCaptchaState] = useState(true);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openErrorModalRecaptcha, setOpenErrorModalRecaptcha] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const [ApplyJob, { data, loading, error }] = useMutation(
    APPLY_FOR_JOB_WITHOUT_JOB_ID,
    {
      onCompleted: (data) => {
        // if(captchaState === false) {
        //   return
        // }
        setOpenSuccessModal(!openSuccessModal);
        return;
      },
      onError: (error) => {
        console.log(error);
        setOpenErrorModal(!openErrorModal);
        return;
      },
    }
  );

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!captchaState) {
      setOpenErrorModalRecaptcha(!openErrorModalRecaptcha);
      return;
    }
    applyJobRes();
  };
  console.log({ data, error, loading });
  console.log(formData);

  const applyJobRes = () => {
    ApplyJob({
      variables: {
        firstName: formData.firstName,
        middleName: formData.middleName,
        lastName: formData.lastName,
        email: formData.email,
        phoneNumber: formData.phone,
        city: formData.city,
        jobSector: formData.jobSector,
        resume: formData.resume,
      },
    });
  };
  error && console.log(error?.networkError?.result?.errors);

  return (
    <Fragment>
      <Header />
      <PageHeader
        bgImg={require("../assets/img/backgroundImages/submit-your-resume-banner.jpg")}
        title="Submit Your Resume"
      />
      <div className={"contact-page-area-wrapper sp-y"}>
        <div className="container">
          <div className="contact-content-wrap">
            <div
              className="contact-form-area contact-method"
              style={{ paddingRight: 30 }}
            >
              <h3 style={{ textAlign: "center", color: "red" }}>
                Submit Resume Form
              </h3>
              {/* <From /> */}
              <div className="contact-form-wrap">
                <form id="contact-form">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="single-input-item">
                        <input
                          type="text"
                          placeholder="First Name*"
                          name="firstName"
                          value={formData.firstName}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="single-input-item">
                        <input
                          type="text"
                          placeholder="Middle Name"
                          name="middleName"
                          value={formData.middleName}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="single-input-item">
                        <input
                          type="text"
                          placeholder="Last Name*"
                          name="lastName"
                          value={formData.lastName}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="single-input-item">
                        <input
                          type="email"
                          placeholder="Email*"
                          name="email"
                          value={formData.email}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="single-input-item">
                        <input
                          type="number"
                          placeholder="Phone"
                          name="phone"
                          value={formData.phone}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="single-input-item">
                        <input
                          type="text"
                          placeholder="City*"
                          name="city"
                          value={formData.city}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="single-input-item">
                        <label>
                          <select
                            name="jobSector"
                            defaultValue={formData.jobSector}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                [e.target.name]: e.target.value,
                              })
                            }
                          >
                            <option value="">Select a Job Sector*</option>
                            <option value="General Labour">
                              General Labour
                            </option>
                            <option value="Forklift Operators">
                              Forklift Operators
                            </option>
                            <option value="Assemblers">Assemblers</option>
                            <option value="Quality Control">
                              Quality Control
                            </option>
                            <option value="Shipping & Receiving">
                              Shipping & Receiving
                            </option>
                            <option value="Light Industrial">
                              Light Industrial
                            </option>
                            <option value="Production Line Support">
                              Production Line Support
                            </option>
                            <option value="Warehouse Clerk">
                              Warehouse Clerk
                            </option>
                            <option value="Office Admin">Office Admin</option>
                            <option value="Meat Trimmers">Meat Trimmers</option>
                            <option value="Machine Operator">
                              Machine Operator
                            </option>
                            <option value="Line Lead">Line Lead</option>
                            <option value="Janitorial">Janitorial</option>
                            <option value="Lumpers">Lumpers</option>
                            <option value="Heavy Lifting">Heavy Lifting</option>
                            <option value="Receptionist">Receptionist</option>
                            <option value="Other">Other</option>
                          </select>
                        </label>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="customFile"
                          name="resume"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [e.target.name]: e.target.files[0],
                            })
                          }
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="customFile"
                        >
                          Upload Resume
                        </label>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="single-input-item">
                        <button
                          id="apply-id-btn-outline"
                          className={"btn-outline"}
                          onClick={handleFormSubmit}
                        >
                          Submit
                        </button>
                      </div>

                      <div className="form-message" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter1"
        centered
        show={openErrorModal}
      >
        <Modal.Header id="contained-modal-title-vcenter1">
          <h3>Message</h3>
        </Modal.Header>
        <Modal.Body>Please fill out all the mandatory fields.</Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => setOpenErrorModal(!openErrorModal)}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter1"
        centered
        show={openErrorModalRecaptcha}
      >
        <Modal.Header id="contained-modal-title-vcenter1">
          <h3>Error</h3>
        </Modal.Header>
        <Modal.Body>Captcha verification is required.</Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => setOpenErrorModalRecaptcha(!openErrorModalRecaptcha)}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter1"
        centered
        show={openSuccessModal}
      >
        <Modal.Header id="contained-modal-title-vcenter1">
          <h3>Success</h3>
        </Modal.Header>
        <Modal.Body>Congratulations! Your form has been submitted.</Modal.Body>
        <Modal.Footer>
          <Link to="/">
            <Button variant="success">OK</Button>
          </Link>
        </Modal.Footer>
      </Modal>

      <Funfact />
      <Footer />
      <LoginRegister />
      <MobileMenu />
    </Fragment>
  );
};

export default SubmitResume;
