import React, { Fragment, useState } from "react";
import Header from "../components/Header";
import BlogPage from "../templates/Blog";
import Funfact from "../components/Funfact";
// import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
import FormInput from "../components/UI/Input";
import PageHeader from "../components/PageHeader";
import { useMutation } from "@apollo/client";
import { ADD_JOB } from "../helpers/graphQL/Mutations";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

const HiringForm = () => {
  const [formData, setFormData] = useState({
    companyName: "",
    companyLogo: null,
    companyPhone: "",
    websiteLink: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",

    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    companyAdminContact: "",
    jobTitle: "",
    jobType: "",
    noOfResourcesRequired: "",
    jobDescriptionFile: null,
    additionalDetails: "",
  });

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const [AddJob, { data, loading, error }] = useMutation(ADD_JOB, {
    onCompleted: (data) => {
      setOpenSuccessModal(!openSuccessModal);
      return;
    },
    onError: (error) => {
      console.log(error);
      setOpenErrorModal(!openErrorModal);
      return;
    },
  });

  const handleFormSubmit = (e) => {
    e.preventDefault();
    addJobRes();
  };
  console.log({ data, error, loading });

  const addJobRes = () => {
    AddJob({
      variables: {
        companyName: formData.companyName,
        companyLogo: formData.companyLogo,
        companyDetailsPhoneNumber: formData.companyPhone,
        websiteLink: formData.websiteLink,
        address: formData.address,
        city: formData.city,
        state: formData.state,
        country: formData.country,
        zipCode: formData.zipCode,

        firstName: formData.firstName,
        middleName: formData.middleName,
        lastName: formData.lastName,
        email: formData.email,
        companyAdminContactNumber: formData.companyAdminContact,
        jobTitle: formData.jobTitle,
        jobType: formData.jobType,
        noOfResourcesRequired: formData.noOfResourcesRequired,
        jobDescriptionFile: formData.jobDescriptionFile,
        additionalDetails: formData.additionalDetails,
      },
    });
  };

  console.log({ formData });
  error && console.log(error);

  return (
    <Fragment>
      <Header />
      <PageHeader
        bgImg={require("../assets/img/backgroundImages/hiring-form-banner.jpg")}
        title="Hiring form"
      />
      <div className={"contact-page-area-wrapper sp-y"}>
        <div className="container">
          <div className="contact-content-wrap">
            <div
              className="contact-form-area contact-method"
              style={{ paddingRight: 30 }}
            >
              <h3>Hiring Form</h3>
              {/* <From /> */}
              <div className="contact-form-wrap">
                <form id="contact-form">
                  <div className="row">
                    <div className="col-md-12">
                      <br></br>
                      <h5 className="red-clr-b">Company Details</h5>
                      <br></br>
                    </div>

                    <div className="col-md-6">
                      <div className="single-input-item">
                        <input
                          type="text"
                          placeholder="Company Name*"
                          name="companyName"
                          value={formData.companyName}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="customFileLogo"
                          name="companyLogo"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [e.target.name]: e.target.files[0],
                            })
                          }
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="customFileLogo"
                        >
                          Company Logo
                        </label>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="single-input-item">
                        <input
                          type="number"
                          placeholder="Phone*"
                          name="companyPhone"
                          value={formData.companyPhone}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="single-input-item">
                        <input
                          type="text"
                          placeholder="Website Link"
                          name="websiteLink"
                          value={formData.websiteLink}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="single-input-item">
                        <input
                          type="text"
                          placeholder="Address*"
                          name="address"
                          value={formData.address}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="single-input-item">
                        <input
                          type="text"
                          placeholder="City*"
                          name="city"
                          value={formData.city}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="single-input-item">
                        <input
                          type="text"
                          placeholder="Province / State*"
                          name="state"
                          value={formData.state}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="single-input-item">
                        <input
                          type="text"
                          placeholder="Country*"
                          name="country"
                          value={formData.country}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="single-input-item">
                        <input
                          type="text"
                          placeholder="Postal Code / Zip Code*"
                          name="zipCode"
                          value={formData.zipCode}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <br></br>
                      <h5 className="red-clr-b">Company Additional Details</h5>
                      <br></br>
                    </div>

                    <div className="col-md-6">
                      <div className="single-input-item">
                        <input
                          type="text"
                          placeholder="First Name*"
                          name="firstName"
                          value={formData.firstName}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="single-input-item">
                        <input
                          type="text"
                          placeholder="Middle Name"
                          name="middleName"
                          value={formData.middleName}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="single-input-item">
                        <input
                          type="text"
                          placeholder="Last Name*"
                          name="lastName"
                          value={formData.lastName}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="single-input-item">
                        <input
                          type="email"
                          placeholder="Email*"
                          name="email"
                          value={formData.email}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="single-input-item">
                        <input
                          type="number"
                          placeholder="Contact Number*"
                          name="companyAdminContact"
                          value={formData.companyAdminContact}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="single-input-item">
                        <input
                          type="text"
                          placeholder="Job Title"
                          name="jobTitle"
                          value={formData.jobTitle}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="single-input-item">
                        <label>
                          <select
                            name="jobType"
                            defaultValue={formData.jobType}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                [e.target.name]: e.target.value,
                              })
                            }
                          >
                            <option value="">Select a Job Sector*</option>
                            <option value="General Labour">
                              General Labour
                            </option>
                            <option value="Forklift Operators">
                              Forklift Operators
                            </option>
                            <option value="Assemblers">Assemblers</option>
                            <option value="Quality Control">
                              Quality Control
                            </option>
                            <option value="Shipping & Receiving">
                              Shipping & Receiving
                            </option>
                            <option value="Light Industrial">
                              Light Industrial
                            </option>
                            <option value="Production Line Support">
                              Production Line Support
                            </option>
                            <option value="Warehouse Clerk">
                              Warehouse Clerk
                            </option>
                            <option value="Office Admin">Office Admin</option>
                            <option value="Meat Trimmers">Meat Trimmers</option>
                            <option value="Machine Operator">
                              Machine Operator
                            </option>
                            <option value="Line Lead">Line Lead</option>
                            <option value="Janitorial">Janitorial</option>
                            <option value="Lumpers">Lumpers</option>
                            <option value="Heavy Lifting">Heavy Lifting</option>
                            <option value="Receptionist">Receptionist</option>
                            <option value="Other">Other</option>
                          </select>
                        </label>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="single-input-item">
                        <label>
                          <select
                            name="noOfResourcesRequired"
                            defaultValue={formData.noOfResourcesRequired}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                [e.target.name]: e.target.value,
                              })
                            }
                          >
                            <option value="">
                              How many employees do you need?
                            </option>
                            <option value="1-10">1-10</option>
                            <option value="11-20">11-20</option>
                            <option value="20+">20+</option>
                          </select>
                        </label>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="customFile"
                          name="jobDescriptionFile"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [e.target.name]: e.target.files[0],
                            })
                          }
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="customFile"
                        >
                          Upload Criteria File
                        </label>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="single-input-item">
                        <textarea
                          cols="30"
                          rows="7"
                          placeholder="Additional notes"
                          name="additionalDetails"
                          value={formData.additionalDetails}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </div>

                      <div className="single-input-item">
                        <button
                          className={"btn-outline"}
                          onClick={handleFormSubmit}
                          id="apply-id-btn-outline"
                        >
                          Submit
                        </button>
                      </div>

                      <div className="form-message" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter1"
        centered
        show={openErrorModal}
      >
        <Modal.Header id="contained-modal-title-vcenter1">
          <h3>Message</h3>
        </Modal.Header>
        <Modal.Body>Please fill out all the mandatory fields.</Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => setOpenErrorModal(!openErrorModal)}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter1"
        centered
        show={openSuccessModal}
      >
        <Modal.Header id="contained-modal-title-vcenter1">
          <h3>Success</h3>
        </Modal.Header>
        <Modal.Body>Congratulations! Your form has been submitted.</Modal.Body>
        <Modal.Footer>
          <Link to="/">
            <Button variant="success">OK</Button>
          </Link>
        </Modal.Footer>
      </Modal>

      <Funfact />
      <Footer />
      <LoginRegister />
      <MobileMenu />
    </Fragment>
  );
};

export default HiringForm;
