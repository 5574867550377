import styled from "styled-components";
export const Container = styled.div`


`;
export const Entity = styled.div`
  color: #070707;
  // border: 0.5px solid #070707;
  max-width: 990px;
  width: 100%;
  // margin-bottom: 10px;
  margin: auto;
  &:first-of-type {

  }
`;
export const Inner = styled.div`
  padding: 75px 40px;

  margin: auto;
  flex-direction: column;
  display: flex;
`;
export const Question = styled.div`
  font: 25px;
  font-size: 1rem;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 2px;
  display: flex;
  font-weight: 500;
  background: rgb(247,247,247);
  padding: 0.75rem 1.12em;
  align-items: center;
  letter-spacing: 1px;
`;
// answer
export const Text = styled.p`
  max-height: 1590px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 1.5px;
  transition: max-height 0.23s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 2em 2.1em 2em 1.4em;
  user-select: none;
  white-space: pre-wrap;
  border: 0.5px solid  rgb(247,247,247);
  @media (max-width: 550px) {
    font-size: 15px;
    line-height: 25px;
  }
`;
export const Header = styled.h1`
  color: #070707;
  line-height: 7;
  margin-top: 0 !important;
  font-size: 45px;
  margin-bottom: 9px;
  text-align: center;

  @media (max-width: 600px) {
    font-size: 33px;
  }

  color: #070707;
`;