import React from "react";
import SlickSlider from "../../components/UI/Slick";
import List from "../../components/UI/List";
import LI from "../../components/UI/List/Item";
import IconBox from "../../components/IconBox";
import Text from "../../components/UI/Text";
import { Link } from "react-router-dom";

import HowWeWorksData from "../../data/HowWeWorks/how-we-works";

const ServiceContentWrap = ({
  service,
  totalService,
  prevService,
  nextService,
}) => {
  return (
    <div className="col-lg-8">
      <div className="service-details-content">
        <div className="service-thumbnail-wrapper">
          <SlickSlider
            settings={{
              className: "service-details-thumb",
              arrows: false,
              dots: true,
            }}
          >
            {service.previewImages.map((previewImg, indx) => (
              <div key={indx}>
                <img
                  src={require("../../assets/img/" + previewImg)}
                  alt={service.title}
                />
              </div>
            ))}
          </SlickSlider>
        </div>

        <div className="service-details-info">
          <h3 style={{ color: "red" }}>{service.title}</h3>
          <p>{service.shortDesc}</p>

          <div className="about-service mt-50 mt-sm-35">
            <h4 style={{ color: "red" }}>About this Service</h4>
            {
              <div
                dangerouslySetInnerHTML={{ __html: service.aboutServiceDesc }}
              />
            }
            <List classes="service-feature mt-30">
              {service.features.map((feature, indx) => (
                <LI key={indx}>
                  {Object.keys(feature) != "NA" ? (
                    <p>
                      <b style={{ color: "red" }}>
                        {Object.keys(feature)}
                        {": "}
                      </b>

                      {Object.values(feature)}
                    </p>
                  ) : (
                    Object.values(feature)
                  )}
                </LI>
              ))}
            </List>

            <br />
            {<div dangerouslySetInnerHTML={{ __html: service.shortDesc2 }} />}
          </div>
          {
            // <div className="how-we-works-wrap mt-50 mt-sm-35">
            //   <h4 style={{ color: "red" }}>How we work</h4>
            //   <Text styles={{ marginBottom: 20 }}>
            //     voluptatum deleniti atque corrupti is es et quas stias cepturi
            //     sint aete non provident, similique sunt dunt ut labore et dolore
            //     magnam aliquam quaerat Neque porro quisquam est, qui dolorem ipsum
            //   </Text>
            //   <div className="how-we-works-content service-details-page">
            //     <div className="row">
            //       {HowWeWorksData.map((iconbox) => (
            //         <IconBox
            //           key={iconbox.id}
            //           icon={iconbox.icon}
            //           title={iconbox.title}
            //           text={iconbox.text}
            //         />
            //       ))}
            //     </div>
            //   </div>
            // </div>
          }
          <div className="pagination-wrap">
            <ul className="pagination">
              <li className="prev">
                <Link
                  to={`${`/service/${prevService.title
                    .split(" ")
                    .join("-")
                    .toLocaleLowerCase()}?id=${prevService.id}`}`}
                  className={service.id === 1 ? "disabled" : null}
                >
                  <i
                    className="fa fa-long-arrow-left"
                    style={{ color: "red" }}
                  />
                  {"  "}
                  Previous
                </Link>
              </li>
              <li className="next">
                <Link
                  to={`${`/service/${nextService.title
                    .split(" ")
                    .join("-")
                    .toLocaleLowerCase()}?id=${nextService.id}`}`}
                  className={service.id === totalService ? "disabled" : null}
                >
                  Next{"  "}
                  <i
                    className="fa fa-long-arrow-right"
                    style={{ color: "red" }}
                  />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceContentWrap;
