import React from "react";
import parse from "html-react-parser";

import { Link } from "react-router-dom";

function SectionTitle(props) {
  return (
    <center>
      <div
        className={`section-title  ${
          props.variant === "light" ? "section-title--light" : ""
        }`}
      >
        {props.title == "Our Services" ? (
          <Link to={"/services"}>
            <h2 style={{ color: "red", fontWeight: 600 }}>{props.title}</h2>
          </Link>
        ) : (
          <h2 style={{ color: "red", fontWeight: 600 }}>{props.title}</h2>
        )}
        <h2 style={{ fontWeight: 600 }}>{parse(props.heading)}</h2>
        {(() => {
          if (props.text) {
            return (
              <p>
                <strong style={{ color: "red" }}>S-Pro Staffing</strong>
                {parse(props.text)}
              </p>
            );
          }
          if (props.tagline) {
            return <h5 className="tagline">{parse(props.tagline)}</h5>;
          }
        })()}
      </div>
    </center>
  );
}

export default SectionTitle;
