import React, { useEffect, useState } from 'react'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { DELETE_JOBS_BY_JOB_ID, GET_ALL_JOBS, GET_ALL_JOBS_COUNT, GET_JOBS_BY_JOB_ID } from '../../helpers/graphQL/Queries'
import { useHistory } from 'react-router-dom'
import { MdArrowBack, MdArrowDownward, MdArrowForward, MdDelete, MdFileDownload, MdHourglassEmpty, MdRemoveRedEye, MdSimCard, MdViewList } from 'react-icons/md'
import { Button, Image, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { PDFDownloadLink } from '@react-pdf/renderer'
import EmployerApplicationsPDF from './EmployerApplicationsPDF'
import { formatDate1, ImgorPdf, validateEmpty } from '../utils/utils'
import Navbar from './Layout/Navbar'
import Sidebar from './Layout/Sidebar'
import DeleteConfirmation from './Popups/DeleteConfirmation'

const EmployerApplications = () => {

    const history = useHistory()
    const token = localStorage.getItem("token")
    if (!token) history.push('/')

    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [page, setPage] = useState(0)

    const [openViewModal, setOpenViewModal] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [deleteID, setDeleteID] = useState("")
    const [viewID, setViewID] = useState("524706b0-a207-47b5-b035-31d537898f20")

    //Get job count
    const jobCount = useQuery(GET_ALL_JOBS_COUNT)

    //Get paginated jobs
    const jobs = useQuery(GET_ALL_JOBS, {
        variables: {
            skip: page * itemsPerPage,
            take: parseInt(itemsPerPage)
        }
    })
    const { refetch } = jobs

    useEffect(() => {
        refetch()
    }, [])

    //Delete a job
    const [delteJobFunc, deleteJob] = useMutation(DELETE_JOBS_BY_JOB_ID)

    const onClickDeleteHandler = async () => {
        await delteJobFunc({ variables: { jobId: parseInt(deleteID) } })
        setDeleteID("")
        setOpenDeleteModal(!openDeleteModal)
        window.location.reload()
    }

    //View a job details
    const [viewJobFunc, viewJob] = useLazyQuery(GET_JOBS_BY_JOB_ID, {
        variables: { jobId: parseInt(viewID), pollInterval: 10 }
    })
    useEffect(() => {
        viewJobFunc()
    }, [viewID])

    return (

        <>
            <Navbar />
            <div className="row m-0 p-0">
                <Sidebar />
                <div className="col" style={{ marginTop: '100px' }}>
                    <h3 className='red-clr-b' style={{ textAlign: 'center' }}>Employer Applications</h3>
                    <section className="" style={{ paddingTop: 10 }}>
                        <div className="">
                            <div style={{ display: 'flex', justifyContent: 'space-between  ' }}>
                                <div>
                                    <div>Total Records: {jobCount?.data?.TotalNumberOfJobs}</div>
                                </div>
                                <div>
                                    <select
                                        className="form-control"
                                        name="itemsPerPage"
                                        defaultValue={itemsPerPage}
                                        onChange={e => {
                                            setItemsPerPage(e.target.value)
                                            setPage(0)
                                        }}
                                    >
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={30}>30</option>
                                        <option value={40}>40</option>
                                        <option value={50}>50</option>
                                    </select>
                                </div>
                            </div>
                            <table className="table table-bordered table-hover text-center mt-3">
                                <thead>
                                    <tr className='table-active'>
                                        <th className='' >Ref #</th>
                                        <th className='' >Added Date</th>
                                        <th className='' >Company Name</th>
                                        <th className='' >Company Phone</th>
                                        <th className=''>Admin Name</th>
                                        <th className=''>Admin Email</th>
                                        <th className=''>Admin Phone</th>
                                        <th className='' colSpan={3}>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        jobs?.loading || jobCount?.loading ?
                                            <tr>
                                                <td colSpan={8}>
                                                    <div className="d-flex justify-content-center my-5">
                                                        <div className="spinner-grow text-danger" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            :
                                            jobs.data.AllJobs.length === 0
                                                ?
                                                <div className="d-flex justify-content-center my-5">
                                                    <span>No records found.</span>
                                                </div>
                                                :
                                                jobs?.data?.AllJobs?.map((row, key) =>
                                                    <tr key={key} >
                                                        <td className=''>{row.job_id}</td>
                                                        <td className=''>{formatDate1(row.created_at)}</td>
                                                        <td className=''>{row.company_name}</td>
                                                        <td className=''>{row.company_details_phone_number}</td>
                                                        <td className=''>{row.first_name}  {row.last_name}</td>
                                                        <td className=''>{row.email}</td>
                                                        <td className=''>{row.company_admin_contact_number}</td>
                                                        <td className=''>
                                                            <OverlayTrigger placement="top"
                                                                overlay={
                                                                    <Tooltip id={`tooltip-top`}>Click to view details.</Tooltip>
                                                                }
                                                            >
                                                                <button
                                                                    className='red-clr'
                                                                    value={row.job_id}
                                                                    onClick={e => {
                                                                        setOpenViewModal(!openViewModal)
                                                                        setViewID(row.job_id)
                                                                    }}
                                                                >
                                                                    <MdRemoveRedEye style={{ fontSize: "20" }} />
                                                                </button>
                                                            </OverlayTrigger>

                                                        </td>
                                                        <td className=''>
                                                            {row.job_description_file ?

                                                                <OverlayTrigger placement="top"
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-top`}>Click to View or Download job critera file.</Tooltip>
                                                                    }
                                                                >
                                                                    <a className='red-clr' href={row.job_description_file} >
                                                                        <MdFileDownload style={{ fontSize: "20" }} />
                                                                    </a>
                                                                </OverlayTrigger>
                                                                :
                                                                <OverlayTrigger placement="top"
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-top`}>No job critera file provided.</Tooltip>
                                                                    }
                                                                >
                                                                    <a href='#' className='red-clr' >---</a>
                                                                </OverlayTrigger>

                                                            }
                                                        </td>
                                                        <td className=''>
                                                            <OverlayTrigger placement="top"
                                                                overlay={
                                                                    <Tooltip id={`tooltip-top`}>Click to Delete this job.</Tooltip>
                                                                }
                                                            >
                                                                <button
                                                                    className='red-clr'
                                                                    value={row.job_id}
                                                                    onClick={e => {
                                                                        setOpenDeleteModal(!openDeleteModal)
                                                                        setDeleteID(row.job_id)
                                                                    }}
                                                                >
                                                                    <MdDelete style={{ fontSize: "20" }} />
                                                                </button>
                                                            </OverlayTrigger>
                                                        </td>
                                                    </tr>
                                                )
                                    }
                                </tbody>
                            </table>

                            <Modal
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                show={openViewModal}
                            >
                                <Modal.Header id="contained-modal-title-vcenter">
                                    <h3>Employer Application Details</h3>
                                </Modal.Header>
                                <Modal.Body>
                                    {viewJob?.loading || viewJob?.data === undefined || viewJob?.data?.JobByJobID === null
                                        ?
                                        <div className="d-flex justify-content-center my-5">
                                            <div className="spinner-grow text-danger" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                        :
                                        <>
                                            <table className='table table-bordered table-popups'>
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th colSpan={2} className='text-center'>Company Detials</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th className='text-primary-st'>Ref No</th>
                                                        <td>{viewJob.data.JobByJobID.job_id}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className='text-primary-st'>Company Name</th>
                                                        <td>{viewJob.data.JobByJobID.company_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className='text-primary-st'>Company Phone</th>
                                                        <td>{viewJob.data.JobByJobID.company_details_phone_number}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className='text-primary-st'>Company Logo</th>
                                                        <td>{ImgorPdf(viewJob.data.JobByJobID.company_logo)}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className='text-primary-st'>Website Link</th>
                                                        <td>{viewJob.data.JobByJobID.website_link}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className='text-primary-st'>Address</th>
                                                        <td>{viewJob.data.JobByJobID.address}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className='text-primary-st'>City</th>
                                                        <td>{viewJob.data.JobByJobID.city}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className='text-primary-st'>State / Province</th>
                                                        <td>{viewJob.data.JobByJobID.state}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className='text-primary-st'>Country</th>
                                                        <td>{viewJob.data.JobByJobID.country}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className='text-primary-st'>Zip Code / Postal Code</th>
                                                        <td>{viewJob.data.JobByJobID.zip_code}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br></br>

                                            <table className='table table-bordered table-popups'>
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th colSpan={2} className='text-center'>Company Admin Detials</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th className='text-primary-st'>First Name</th>
                                                        <td>{viewJob.data.JobByJobID.first_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className='text-primary-st'>Middle Name</th>
                                                        <td>{validateEmpty(viewJob.data.JobByJobID.middle_name)}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className='text-primary-st'>Last Name</th>
                                                        <td>{viewJob.data.JobByJobID.last_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className='text-primary-st'>Email</th>
                                                        <td>{viewJob.data.JobByJobID.email}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className='text-primary-st'>Phone</th>
                                                        <td>{viewJob.data.JobByJobID.company_details_phone_number}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br></br>

                                            <table className='table table-bordered table-popups'>
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th colSpan={2} className='text-center'>Job Detials</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th className='text-primary-st'>Job Title</th>
                                                        <td>{validateEmpty(viewJob.data.JobByJobID.job_title)}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className='text-primary-st'>Job Type</th>
                                                        <td>{viewJob.data.JobByJobID.job_type}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className='text-primary-st'>Required No. of Employees</th>
                                                        <td>{validateEmpty(viewJob.data.JobByJobID.no_of_resources_required)}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className='text-primary-st'>Job Criteria File</th>
                                                        <td>{ImgorPdf(viewJob.data.JobByJobID.job_description_file)}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className='text-primary-st'>Additional Details / Notes</th>
                                                        <td>{validateEmpty(viewJob.data.JobByJobID.additional_details)}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </>
                                    }
                                </Modal.Body>
                                <Modal.Footer>
                                    <PDFDownloadLink
                                        document={<EmployerApplicationsPDF viewJob={viewJob} />}
                                        fileName={`Employer Application #${viewID}`}
                                    >
                                        {viewJob && <Button variant='success'>Download form</Button>}
                                    </PDFDownloadLink>
                                    <Button variant='danger' onClick={() => setOpenViewModal(!openViewModal)}>Close</Button>
                                </Modal.Footer>
                            </Modal>

                            <DeleteConfirmation
                                show={openDeleteModal}
                                heading="Delete Job"
                                text={`Are you sure you want to delete the job with Ref# ${deleteID}?`}
                                onClickSuccess={onClickDeleteHandler}
                                onClickCancel={() => { setOpenDeleteModal(!openDeleteModal) }}
                            />

                            {!jobs?.loading &&
                                <div >
                                    <ul className="pagination" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <li className="page-item mr-3">
                                            <button
                                                className="page-link"
                                                aria-label="Previous"
                                                disabled={!page} onClick={() => setPage(prev => prev - 1)}
                                            >
                                                <span aria-hidden="true"><MdArrowBack className='red-clr' style={{ fontSize: "20" }} /></span>
                                                <span className="sr-only">Previous</span>
                                            </button>
                                        </li>
                                        {page + 1} of {Math.round((jobCount?.data?.TotalNumberOfJobs / itemsPerPage) + 0.5)}
                                        <li className="page-item ml-3">
                                            <button
                                                className="page-link"
                                                aria-label="Next"
                                                disabled={page >= (Math.round((jobCount?.data?.TotalNumberOfJobs / itemsPerPage) + 0.5) - 1)}
                                                onClick={() => setPage(prev => prev + 1)}
                                            >
                                                <span aria-hidden="true"><MdArrowForward className='red-clr' style={{ fontSize: "20" }} /></span>
                                                <span className="sr-only">Next</span>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            }

                        </div>
                    </section >

                </div >
            </div>
        </>
    )
}
export default EmployerApplications