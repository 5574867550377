import React, { useState, useEffect } from "react";
import SectionTitle from "../UI/SectionTitle";
import BlogItem from "./blogItem";
import LoadingSpinner from "../Spinner";
function Blog() {
  const [data, setData] = useState(null);
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);
  let inputvariables = {
    skip: 0,
    take: 3,
  };
  const endpoint =
    "https://haexud92rg.execute-api.ca-central-1.amazonaws.com/backend/graphql";
  const query = `query AllAgencyJobPosts($skip: Int!, $take: Int!) {
  AllAgencyJobPosts(skip: $skip, take: $take) {
    agency_job_post_job_id
    job_title
    job_description
  }
}

`;

  useEffect(() => {
    fetch(endpoint, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        query,
        variables: inputvariables,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        setData(result.data.AllAgencyJobPosts);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <div className="blog-area-wrapper sm-top">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <SectionTitle title="Find Appropriate" heading="Active Jobs" />
          </div>
        </div>

        <div className="row mtn-35">
          {data != null ? (
            data.map((blog) => (
              <BlogItem
                key={blog.agency_job_post_job_id}
                agency_job_post_job_id={blog.agency_job_post_job_id}
                job_title={blog.job_title}
                job_description={blog.job_description}
              />
            ))
          ) : (
            <LoadingSpinner class="page-header-content"> </LoadingSpinner>
          )}
        </div>
      </div>
    </div>
  );
}

export default Blog;
