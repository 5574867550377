import React, { Fragment } from "react";
import Header from "../components/Header";
import ContactPage from "../templates/Contact";

import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
import PageHeader from "../components/PageHeader";
const PageContact = () => {
  return (
    <Fragment>
      <Header />
      <PageHeader
        bgImg={require("../assets/img/backgroundImages/contat-us-banner.jpg")}
        title="Contact Us"
      />
      <ContactPage />
      <Footer />
      <LoginRegister />
      <MobileMenu />
    </Fragment>
  );
};

export default PageContact;
