import React, { useState } from "react";
import { useMutation } from "@apollo/client"
import { ADD_AGENCY_JOB_POST, ADD_JOB } from "../../helpers/graphQL/Mutations";
import { Link, useHistory } from "react-router-dom";
import { TagsInput } from "react-tag-input-component";
import { Button, Modal } from "react-bootstrap";
import Navbar from "./Layout/Navbar";
import Sidebar from "./Layout/Sidebar";
import { GraphQLErrorHandler } from '../utils/utils'

const JobPost = () => {

    const initialDate = new Date().toISOString().split("T")
    const history = useHistory()
    const token = localStorage.getItem("token")
    if (!token) history.push('/')

    const [AddJobFunc, addJob] = useMutation(ADD_AGENCY_JOB_POST, {
        onCompleted: data => {
            setOpenSuccessModal(!openSuccessModal)
            return
        },
        onError: (error) => {
            setOpenErrorModal(!openErrorModal)
            return
        }
    })

    const [workersRepotState, setWorkersRepotState] = useState(false)
    const [openErrorModal, setOpenErrorModal] = useState(false)
    const [openEmailErrorModal, setOpenEmailErrorModal] = useState(false)
    const [openSuccessModal, setOpenSuccessModal] = useState(false)

    const [skills, setSkills] = useState([])
    const [requirements, setRequirements] = useState([])
    const [formData, setFormData] = useState({

        saveAsATemplate: false,
        language: '',
        country: '',
        companyName: '',
        location: '',
        jobTitle: '',
        jobSector: '',
        jobDescription: '',
        requiredQualification: '',
        noOfResourcesRequired: 0,

        workingLocation: '',
        startDate: '',
        startTime: '',
        startTimeZone: '',
        endTime: '',
        endTimeZone: '',
        payRate: 0,
        payRateCurrency: '',
        priority: 1,
        safetyShoes: false,
        safetyGloves: false,
        safetyJacket: false,
    })

    const handleFormSubmit = e => {
        e.preventDefault()
        addJobRes()
    }

    const addJobRes = () => {
        AddJobFunc({
            variables: {
                jobId: null,
                saveAsATemplate: false,
                language: formData.language,
                country: formData.country,
                companyName: formData.companyName,
                location: formData.location,
                workingLocation: workersRepotState ? "" : formData.workingLocation,
                jobTitle: formData.jobTitle,
                jobSector: formData.jobSector,
                jobDescription: formData.jobDescription,
                requiredQualification: formData.requiredQualification,
                noOfResourcesRequired: formData.noOfResourcesRequired,
                skills: skills,
                requirements: requirements,

                startDate: new Date(formData.startDate),
                startTime: formData.startTime ? `${initialDate[0]}T${formData.startTime}:00.000Z` : null,
                startTimeZone: formData.startTimeZone,
                endTime: formData.endTime ? `${initialDate[0]}T${formData.endTime}:00.000Z` : null,
                endTimeZone: formData.endTimeZone,
                payRate: formData.payRate,
                payRateCurrency: formData.payRateCurrency,
                priority: formData.priority,
                safetyShoes: formData.safetyShoes,
                safetyGloves: formData.safetyGloves,
                safetyJacket: formData.safetyJacket,
            }
        })
    }

    GraphQLErrorHandler(addJob)

    return (
        <>
            <Navbar />
            <div className="row m-0 p-0">
                <Sidebar />
                <div className="col" style={{ marginTop: '100px' }}>
                    <h3 className='red-clr-b' style={{ textAlign: 'center' }}>Job Post Form</h3>
                    <section className="create-job padd-top-0 padd-bot-40">
                        <form className="c-form" onSubmit={handleFormSubmit}>
                            <div className="box">
                                <div className="box-header">
                                    <h4>Company Details</h4>
                                </div>
                                <div className="box-body">
                                    <div className="row">
                                        <div className="form-group col-md-4 col-sm-4 col-xs-12">
                                            <label>Company Name</label><span style={{ color: 'red' }}> *</span>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Company name"
                                                name="companyName"
                                                value={formData.companyName}
                                                onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                            />
                                        </div>

                                        <div className="form-group col-md-4 col-sm-4 col-xs-12 m-clear">
                                            <label>Language</label><span style={{ color: 'red' }}> *</span>
                                            <select
                                                className="wide form-control"
                                                name="language"
                                                value={formData.language}
                                                onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                            >
                                                <option value="">--Select an option--</option>
                                                <option value="English">English</option>
                                                <option value="Spanish">Spanish</option>
                                                <option value="French">French</option>
                                            </select>
                                        </div>

                                        <div className="form-group col-md-4 col-sm-6 col-xs-12">
                                            <label>Country</label><span style={{ color: 'red' }}> *</span>
                                            <select
                                                className="wide form-control"
                                                name="country"
                                                value={formData.country}
                                                onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                            >
                                                <option value="">--Select an option--</option>
                                                <option value="Canada">Canada</option>
                                                <option value="USA">USA</option>
                                            </select>
                                        </div>

                                        <div className="form-group col-md-6 col-sm- col-xs-12">
                                            <label>Location</label><span style={{ color: 'red' }}> *</span>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Location"
                                                name="location"
                                                value={formData.location}
                                                onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                            />
                                        </div>

                                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                            <label style={{ color: "transparent" }}>Workers will report here</label>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="id1"
                                                    value={workersRepotState}
                                                    onChange={e => setWorkersRepotState(e.target.checked)}
                                                />
                                                <label className="form-check-label" htmlFor="id1">
                                                    Workers will report here
                                                </label>
                                            </div>
                                        </div>

                                        {!workersRepotState &&
                                            <>
                                                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                    <label>Working Location</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Working location"
                                                        name="workingLocation"
                                                        value={formData.workingLocation}
                                                        onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                                    />
                                                </div>

                                                <div className="col-md-4 col-sm-4 col-xs-12">
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <br></br>
                                </div>
                                <hr />

                                <div className="box-header">
                                    <h4>Job Details</h4>
                                </div>
                                <div className="box-body">
                                    <div className="row">

                                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                            <label>Job Title</label><span style={{ color: 'red' }}> *</span>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Job title"
                                                name="jobTitle"
                                                value={formData.jobTitle}
                                                onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                            />
                                        </div>

                                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                            <label>Job Sector</label><span style={{ color: 'red' }}> *</span>
                                            <select
                                                className="wide form-control"
                                                name="jobSector"
                                                value={formData.jobSector}
                                                onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                            >
                                                <option value="">--Select and option--</option>
                                                <option value="Administrative support">Administrative support</option>
                                                <option value="Contact centre and customer care">Contact centre and customer care</option>
                                                <option value="Engineering">Engineering</option>
                                                <option value="Finance and accounting">Finance and accounting</option>
                                                <option value="Healthcare">Healthcare</option>
                                                <option value="Human resources">Human resources</option>
                                                <option value="Industrial support">Industrial support</option>
                                                <option value="Sales and marketing">Sales and marketing</option>
                                                <option value="Skilled trades and industrial management">Skilled trades and industrial management</option>
                                                <option value="Technologies">Technologies</option>
                                                <option value="Legal">Legal</option>
                                                <option value="Manufacturing">Manufacturing</option>
                                            </select>
                                        </div>

                                        <div className="form-group col-md-12 col-sm-12 col-xs-12 m-clear" >
                                            <label>Job Description</label>
                                            <span style={{ color: 'red' }}> *</span>
                                            <textarea
                                                className="form-control"
                                                style={{ height: 100 }}
                                                placeholder="Job description"
                                                name="jobDescription"
                                                value={formData.jobDescription}
                                                onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                            />
                                        </div>

                                        <div className="form-group col-md-4 col-sm-4 col-xs-12 m-clear">
                                            <label>Qualifications</label>
                                            <select
                                                className="wide form-control"
                                                name="requiredQualification"
                                                value={formData.requiredQualification}
                                                onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                            >
                                                <option value="">--Select and option--</option>
                                                <option value="Intermediate">Intermediate</option>
                                                <option value="Bachelor">Bachelor</option>
                                                <option value="Master">Master</option>
                                                <option value="None">None</option>
                                            </select>
                                        </div>

                                        <div className="form-group col-md-4 col-sm-4 col-xs-12">
                                            <label>Skills Required</label>
                                            <TagsInput
                                                placeHolder="Type & press enter"
                                                name="skills"
                                                value={skills}
                                                onChange={setSkills}
                                            />
                                        </div>

                                        <div className="form-group col-md-4 col-sm-4 col-xs-12">
                                            <label>Requirements</label>
                                            <TagsInput
                                                placeHolder="Type & press enter"
                                                name="requirements"
                                                value={requirements}
                                                onChange={setRequirements}
                                            />
                                        </div>

                                        <div className="form-group col-md-6 col-sm-6 col-xs-12 m-clear">
                                            <label>How many people do you want to hire for this opening?</label><span style={{ color: 'red' }}> *</span>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                placeholder="No. of vacancies"
                                                name="noOfResourcesRequired"
                                                value={formData.noOfResourcesRequired}
                                                onChange={e => setFormData({ ...formData, [e.target.name]: parseInt(e.target.value) })}
                                            />
                                        </div>

                                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                            <label>Start Date</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                placeholder="Required knowledge"
                                                name="startDate"
                                                value={formData.startDate}
                                                onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                            />
                                        </div>

                                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                            <label>Start Time</label>
                                            <input
                                                type="time"
                                                className="form-control"
                                                placeholder="Required knowledge"
                                                name="startTime"
                                                value={formData.startTime}
                                                onChange={e => {
                                                    setFormData({ ...formData, [e.target.name]: e.target.value })
                                                }}
                                            />

                                        </div>

                                        <div className="form-group col-md-6 col-sm-6 col-xs-12 m-clear">
                                            <label>Start Timezone</label>
                                            <select
                                                className="wide form-control"
                                                name="startTimeZone"
                                                value={formData.startTimeZone}
                                                onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                            >
                                                <option value="">--Select an option--</option>
                                                <option value="EST">EST</option>
                                                <option value="PST">PST</option>
                                                <option value="CST">CST</option>
                                            </select>
                                        </div>

                                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                            <label>End Time</label>
                                            <input
                                                type="time"
                                                className="form-control"
                                                placeholder="Required knowledge"
                                                name="endTime"
                                                value={formData.endTime}
                                                onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                            />
                                        </div>

                                        <div className="form-group col-md-6 col-sm-6 col-xs-12 m-clear">
                                            <label>End Timezone</label>
                                            <select
                                                className="wide form-control"
                                                name="endTimeZone"
                                                value={formData.endTimeZone}
                                                onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                            >
                                                <option value="">--Select an option--</option>
                                                <option value="EST">EST</option>
                                                <option value="PST">PST</option>
                                                <option value="CST">CST</option>
                                            </select>
                                        </div>

                                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                            <label>Pay Rate</label>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                placeholder="Pay rate"
                                                name="payRate"
                                                value={formData.payRate}
                                                onChange={e => setFormData({ ...formData, [e.target.name]: parseFloat(e.target.value) })}
                                            />
                                        </div>

                                        <div className="form-group col-md-6 col-sm-6 col-xs-12 m-clear">
                                            <label>Pay Rate Currency</label>
                                            <select
                                                className="wide form-control"
                                                name="payRateCurrency"
                                                value={formData.payRateCurrency}
                                                onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                            >
                                                <option value="">--Select an option--</option>
                                                <option value="USD">USD</option>
                                                <option value="CAD">CAD</option>
                                            </select>
                                        </div>

                                        <div className="form-group col-md-6 col-sm-6 col-xs-12 m-clear">
                                            <label>Priority</label>
                                            <select
                                                className="wide form-control"
                                                name="priority"
                                                defaultValue={formData.priority}
                                                onChange={e => setFormData({ ...formData, [e.target.name]: parseFloat(e.target.value) })}
                                            >
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </select>
                                        </div>

                                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                            <label>Employee Requirements</label>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="flexCheckDefault1"
                                                    name="safetyShoes"
                                                    checked={formData.safetyShoes}
                                                    onChange={e => setFormData({ ...formData, [e.target.name]: e.target.checked })}
                                                />
                                                <label className="form-check-label" htmlFor="flexCheckDefault1">
                                                    Safety Shoes
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="flexCheckDefault2"
                                                    name="safetyGloves"
                                                    checked={formData.safetyGloves}
                                                    onChange={e => setFormData({ ...formData, [e.target.name]: e.target.checked })}
                                                />
                                                <label className="form-check-label" htmlFor="flexCheckDefault2">
                                                    Safety Gloves
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="flexCheckDefault3"
                                                    name="safetyJacket"
                                                    checked={formData.safetyJacket}
                                                    onChange={e => setFormData({ ...formData, [e.target.name]: e.target.checked })}
                                                />
                                                <label className="form-check-label" htmlFor="flexCheckDefault3">
                                                    Safety Jacket
                                                </label>
                                            </div>
                                        </div>

                                        <div className="form-group col-md-12 col-sm-12 col-xs-12 text-right">
                                            {addJob?.loading ?
                                                <button className="btn btn-success" type="button" disabled>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    <span className="sr-only">Loading...</span>
                                                </button>
                                                :
                                                <button type="submit" className="btn btn-success">Submit</button>
                                            }
                                        </div>

                                    </div>
                                    <br></br>
                                </div>
                            </div>
                            <br></br>

                        </form>
                    </section >

                    <Modal
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter1"
                        centered
                        show={openErrorModal}
                    >
                        <Modal.Header id="contained-modal-title-vcenter1">
                            <h3>Message</h3>
                        </Modal.Header>
                        <Modal.Body>
                            Please fill out all the mandatory fields.
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant='danger' onClick={() => setOpenErrorModal(!openErrorModal)}>
                                OK
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter1"
                        centered
                        show={openEmailErrorModal}
                    >
                        <Modal.Header id="contained-modal-title-vcenter1">
                            <h3>Error</h3>
                        </Modal.Header>
                        <Modal.Body>
                            Please enter a valid email.
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant='danger' onClick={() => setOpenErrorModal(!openErrorModal)}>
                                OK
                            </Button>
                        </Modal.Footer>
                    </Modal>


                    <Modal
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter1"
                        centered
                        show={openSuccessModal}
                    >
                        <Modal.Header id="contained-modal-title-vcenter1">
                            <h3>Success</h3>
                        </Modal.Header>
                        <Modal.Body>
                            Congratulations! Your form has been submitted.
                        </Modal.Body>
                        <Modal.Footer>
                            <Link to="/admin/posted-jobs" >
                                <Button variant='success'>OK</Button>
                            </Link>
                        </Modal.Footer>
                    </Modal>

                </div>
            </div>
        </>
    );
}

export default JobPost