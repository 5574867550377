import React from "react";
import { Link } from "react-router-dom";

function BlogItem(props) {
  const blogURL = `/Job-details/${props.agency_job_post_job_id}`;
  return (
    <div className={props.cols ? props.cols : "col-md-8 col-lg-4"}>
      <div className="blog-item">
        {props.thumb ? (
          <figure className="blog-thumb">
            <Link to={`${blogURL}`}>
              <img
                src={require("../../assets/img/" + props.thumb)}
                alt={props.job_title}
              />
            </Link>
          </figure>
        ) : null}
        <div
          className="blog-content"
          style={{ textAlign: "center", width: "100%" }}
        >
          <h4 style={{ color: "red" }}>
            <b> {props.job_title}</b>
          </h4>
          {
            // <p style={{ height: "80px", display: "none" }}>
            //   {" "}
            //   {props.job_description.length > 100
            //     ? props.job_description.substring(0, 100) + "..."
            //     : props.job_description}{" "}
            // </p>
          }

          <div className="locationMark" style={{ textAlign: "center" }}>
            {
              // <i class="fa fa-map-marker fa_custom"></i>
            }
            {props.job_description.length > 130
              ? props.job_description.split(/\s+/).slice(0, 20).join(" ")
                  .length <= 130
                ? props.job_description.split(/\s+/).slice(0, 20).join(" ") +
                  "..."
                : props.job_description.split(/\s+/).slice(0, 15).join(" ")
                    .length <= 130
                ? props.job_description.split(/\s+/).slice(0, 13).join(" ") +
                  "..."
                : props.job_description.split(/\s+/).slice(0, 10).join(" ")
                    .length <= 130
                ? props.job_description.split(/\s+/).slice(0, 10).join(" ") +
                  "..."
                : props.job_description.split(/\s+/).slice(0, 5).join(" ")
                    .length <= 130
                ? props.job_description.split(/\s+/).slice(0, 5).join(" ") +
                  "..."
                : props.job_description
              : props.job_description}
          </div>
          <br />
          <div className="applyNow">
            {" "}
            <Link style={{ color: "red" }} to={`${blogURL}`}>
              {"Apply Now!"}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogItem;
