import React from "react";
import quoteIcon from "../../../assets/img/icons/quote11.png";

function TestimonialItem(props) {
  return (
    <div className="testimonial-item testimonial-item--3">
      <div className="testimonial-thumb">
        <img
          src={require("../../../assets/img/" + props.authorThumb)}
          alt="S-Pro Staffing"
        />
      </div>

      <div className="testimonial-txt" style={{ height: "260px" }}>
        <img fill="red" src={quoteIcon} alt="S-Pro Staffing" />
        <p>{props.quote}</p>
        <h5 style={{ color: "red" }} className="client-name">
          {props.author}
        </h5>
      </div>
    </div>
  );
}

export default TestimonialItem;
