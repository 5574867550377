import React from "react";
import Text from "../UI/Text";
import Widget from "../UI/Widget";
import List from "../UI/List";
import LI from "../UI/List/Item";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/logo-dark.png";

function Footer() {
  return (
    <footer className="footer-area sp-bottom">
      <div className="container">
        <div className="row mtn-40">
          <div className="col-lg-4 order-4 order-lg-0">
            <div className="widget-item">
              <div className="about-widget">
                <Link to={`${"/"}`}>
                  <img src={Logo} alt="Logo" />
                </Link>

                <Text>
                  We guarantee that we are able to identify and deliver to our
                  clients the best talent available on the market today.
                </Text>

                <Text classes="copyright-txt">
                  Copyright &copy; {new Date().getFullYear()}
                  <a
                    href={"https://www.aamanto.com"}
                    style={{
                      color: "red",
                    }}
                  >
                    {" "}
                    Aamanto Technologies Inc.
                  </a>
                </Text>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-lg-2 ml-auto">
            <Widget title="Information">
              <List classes="widget-list">
                <LI>
                  <Link to={`${"/services"}`}>Our Services</Link>
                </LI>
                <LI>
                  <Link to={`${"/contact"}`}>Contact Us</Link>
                </LI>
                <LI>
                  <Link to={`${"/about"}`}>About Us</Link>
                </LI>
              </List>
            </Widget>
          </div>

          <div className="col-md-4 col-lg-2 ml-auto">
            <Widget title="Social Links">
              <List classes="widget-list">
                <LI>
                  <a
                    style={{
                      color: "black",
                    }}
                    href="http://www.facebook.com/sprostaffing"
                    target={"_blank"}
                  >
                    Facebook
                  </a>
                </LI>

                <LI>
                  <a
                    style={{
                      color: "black",
                    }}
                    href="http://www.linkedin.com/company/sprostaffing"
                    target={"_blank"}
                  >
                    Linkedin
                  </a>
                </LI>
                <LI>
                  <a
                    style={{
                      color: "black",
                    }}
                    href="http://www.instagram.com/sprostaffing"
                    target={"_blank"}
                  >
                    Instagram
                  </a>
                </LI>
              </List>
            </Widget>
          </div>

          <div className="col-md-4 col-lg-3">
            <Widget title="Contact Us">
              <address>
                1315 Derry Road East, Unit 1 Mississauga, Ontario L5T 1B6
                <br />
                info@sprostaffing.com <br />
                (+1) 800-640-1333
                <br />
                (+1) 416-688-0871
              </address>
            </Widget>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
