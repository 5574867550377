import React, { useEffect, useState } from "react";
import SlickSlider from "../../UI/Slick";
import SliderData from "../../../data/Slider/home-1";
import { Link, useHistory } from "react-router-dom";
import Handshake from "../../../assets/img/slider/Handshake.mp4";
import FormInput from "../../../components/UI/Input";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_LOCATION_DROPDOWN } from "../../../helpers/graphQL/Queries";
import ReactSelect from "react-select";

const NextArrow = ({ className, onClick }) => {
  return (
    <button className={className} onClick={onClick}>
      <i className="fa fa-angle-right" />
    </button>
  );
};

const PrevArrow = ({ className, onClick }) => {
  return (
    <button className={className} onClick={onClick}>
      <i className="fa fa-angle-left" />
    </button>
  );
};

const Slider = () => {
  const settings = {
    arrows: true,
    dots: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  //API
  const options = [];
  const history = useHistory();

  const [show, setShow] = useState(false);
  const [titleState, setTitleState] = useState("");
  const [locationState, setLocationState] = useState("");
  const [jobSectorState, setJobSectorState] = useState("");
  const [rangeState, setRangeState] = useState("");
  const [onChangeAddressState, setOnChangeAddressState] = useState("");

  const onClickHandler = () => {
    history.push(
      `/Search-jobs?title=${titleState}&location=${
        !locationState.value ? "" : locationState.value
      }&range=${rangeState}`
    );
  };

  //Location
  const [getLocationDropdownFunc, getLocationDropdown] = useLazyQuery(
    GET_LOCATION_DROPDOWN,
    {
      variables: {
        address: onChangeAddressState ? onChangeAddressState : "Canada",
      },
    }
  );

  useEffect(() => {
    getLocationDropdownFunc();
  }, []);

  const updateDropDownFunc = () => {
    return getLocationDropdown?.data?.addressList.map((row, key) =>
      options.push({ value: row, label: row })
    );
  };
  updateDropDownFunc();

  return (
    <div className={"slider-area"}>
      {SliderData.map((item) => (
        <div key={item.id}>
          <div className="slider-item">
            <video className="background-video" autoPlay loop muted>
              <source
                src="https://backend-staging-bucket.s3.ca-central-1.amazonaws.com/Video+for+home.mp4"
                type="video/mp4"
              />
            </video>
            <div className="col-md-12">
              <div className="row mb-5">
                <div className="col-lg-7 m-auto text-center">
                  <h4 className="LetUs1">
                    <strong
                      className="LetUs"
                      style={{
                        color: "white",
                        padding: 10,
                        backgroundColor: "#ea2328",
                        borderRadius: 5,
                        marginTop: 20,
                      }}
                    >
                      {"LET US EMPOWER YOUR WORK FORCE"}
                    </strong>
                  </h4>

                  <h1 className="white-clr LetUs">
                    Using professional expertise to help employers recruit
                    effectively.
                  </h1>
                </div>
              </div>
              <form className="input-form" onSubmit={onClickHandler}>
                <div className="container">
                  <div className="row">
                    <div className="col-md-3">
                      <input
                        className="input-Text"
                        type="text"
                        style={{
                          width: "100%",
                          height: "50px",
                          marginBottom: "10px",
                          paddingLeft: "10px",
                          borderRadius: 5,
                          borderColor: "transparent",
                        }}
                        placeholder="Job title or keywords"
                        value={titleState}
                        onChange={(e) => setTitleState(e.target.value)}
                      />
                    </div>

                    <div className="col-md-3">
                      <ReactSelect
                        placeholder="Location or postal code"
                        options={options}
                        onInputChange={(value) =>
                          setOnChangeAddressState(value)
                        }
                        onChange={(value) => setLocationState(value)}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            height: 50,
                            borderRadius: 2,
                            width: "100%",
                            marginBottom: "10px",
                            paddingLeft: "0px",
                            borderRadius: 5,
                            borderColor: "transparent",
                          }),
                          placeholder: (baseStyles) => {
                            return {
                              ...baseStyles,
                              fontWeight: "normal",

                              paddingLeft: 0,
                              textAlign: "left",
                            };
                          },
                        }}
                      />
                    </div>

                    <div className="col-md-3">
                      <input
                        type="number"
                        style={{
                          width: "100%",
                          height: "50px",
                          marginBottom: "10px",
                          paddingLeft: "10px",
                          borderRadius: 5,
                          borderColor: "transparent",
                        }}
                        placeholder="Range (km)"
                        value={rangeState}
                        onChange={(e) => setRangeState(e.target.value)}
                      />
                    </div>
                    <div className="col-md-3">
                      <input
                        className="job-btn-submit"
                        type="submit"
                        style={{
                          width: "100%",
                          height: "50px",
                          marginBottom: "10px",
                          borderRadius: 5,

                          borderColor: "transparent",
                        }}
                        value={"Find Jobs"}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  // return (
  //   <div className={"slider-area"}>
  //     <SlickSlider settings={settings}>
  //       {SliderData.map((item) => (
  //         <div key={item.id}>
  //           <div
  //             className="slider-item"
  //             // style={{
  //             //   backgroundImage: `url(${require("../../../assets/img/" +
  //             //     item.bg)})`,
  //             // }}
  //           >
  //             <video className="background-video" autoPlay loop muted>
  //               <source src={Handshake} type="video/mp4" />
  //             </video>
  //             <div className="container">
  //               <div className="row">
  //                 <div className="col-xl-7">
  //                   <div className="slider-content">
  //                     <h2>{item.title}</h2>
  //                     <p>{item.text}</p>
  //                     <Link to={`${item.btnLink}`} className="btn btn-brand">
  //                       {item.btnText}
  //                     </Link>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       ))}
  //     </SlickSlider>
  //   </div>
  // );
};
export default Slider;
