import React from "react";

function FeatureItem(props) {
  return (
    <div className="col-lg-4">
      <div className="icon-box-item">
        {/*      <div className="icon-box__icon">
          <img
          style={{}}
            src={require("../../assets/img/" + props.img)}
            alt="S-Pro Staffing-Feature"
          />
        </div> */}
        <div>
          <h5>
            <b>{props.title}</b>
          </h5>

          <p>{props.text}</p>
        </div>
      </div>
    </div>
  );
}

export default FeatureItem;
