import React, { Fragment } from "react";
import Header from "../components/Header";
import BlogPage from "../templates/Blog";
import Funfact from "../components/Funfact";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";

const JobSearch = () => {
  return (
    <Fragment>
      <Header />
      <BlogPage blog_type={"grid"} sidebar={true} sidebar_position={"right"} />
      <Funfact />
      <Footer />
      <LoginRegister />
      <MobileMenu />
    </Fragment>
  );
};

export default JobSearch;
