import React from 'react'
import { Document, Page, Text, StyleSheet, View } from '@react-pdf/renderer'
import { formatDate, formatTimeFromDB } from '../utils/utils'

const styles = StyleSheet.create({
    body: {
        padding: 35
    },
    headingDiv: {
        textAlign: 'center',
        paddingBottom: 20,
    },
    heading: {
        fontWeight: 'bolder',
        fontSize: 24,
    },
    title: {
        color: '#04acee',
        paddingBottom: 5
    },
    description: {
        color: '#707f8c',
        paddingBottom: 10
    }
})

const PostedJobsPDF = ({ viewPost }) => {
    return (
        <Document>
            <Page style={styles.body}>
                <View style={styles.headingDiv}>
                    <Text style={styles.heading}>Posted Job</Text>
                </View>
                <Text style={styles.title}>Posted Job ID:</Text>
                <Text style={styles.description}>{!viewPost?.data?.AgencyJobPostByAgencyJobPostID?.agency_job_post_job_id ? '---' : viewPost?.data?.AgencyJobPostByAgencyJobPostID?.agency_job_post_job_id}</Text>
                <Text style={styles.title}>Language:</Text>
                <Text style={styles.description}>{!viewPost?.data?.AgencyJobPostByAgencyJobPostID?.language ? '---' : viewPost?.data?.AgencyJobPostByAgencyJobPostID?.language}</Text>
                <Text style={styles.title}>Country:</Text>
                <Text style={styles.description}>{!viewPost?.data?.AgencyJobPostByAgencyJobPostID?.country ? '---' : viewPost?.data?.AgencyJobPostByAgencyJobPostID?.country}</Text>
                <Text style={styles.title}>Company Name:</Text>
                <Text style={styles.description}>{!viewPost?.data?.AgencyJobPostByAgencyJobPostID?.company_name ? '---' : viewPost?.data?.AgencyJobPostByAgencyJobPostID?.company_name}</Text>
                <Text style={styles.title}>Location:</Text>
                <Text style={styles.description}>{!viewPost?.data?.AgencyJobPostByAgencyJobPostID?.location ? '---' : viewPost?.data?.AgencyJobPostByAgencyJobPostID?.location}</Text>
                <Text style={styles.title}>Working Location:</Text>
                <Text style={styles.description}>{!viewPost?.data?.AgencyJobPostByAgencyJobPostID?.working_location ? '---' : viewPost?.data?.AgencyJobPostByAgencyJobPostID?.working_location}</Text>
                <Text style={styles.title}>Job Title:</Text>
                <Text style={styles.description}>{!viewPost?.data?.AgencyJobPostByAgencyJobPostID?.job_title ? '---' : viewPost?.data?.AgencyJobPostByAgencyJobPostID?.job_title}</Text>
                <Text style={styles.title}>Job Sector:</Text>
                <Text style={styles.description}>{!viewPost?.data?.AgencyJobPostByAgencyJobPostID?.job_sector ? '---' : viewPost?.data?.AgencyJobPostByAgencyJobPostID?.job_sector}</Text>
                <Text style={styles.title}>Job Description:</Text>
                <Text style={styles.description}>{!viewPost?.data?.AgencyJobPostByAgencyJobPostID?.job_description ? '---' : viewPost?.data?.AgencyJobPostByAgencyJobPostID?.job_description}</Text>
                <Text style={styles.title}>Required Qualification</Text>
                <Text style={styles.description}>{!viewPost?.data?.AgencyJobPostByAgencyJobPostID?.required_qualification ? '---' : viewPost?.data?.AgencyJobPostByAgencyJobPostID?.required_qualification}</Text>
                <Text style={styles.title}>Skills:</Text>
                <Text style={styles.description}>
                    {viewPost?.data?.AgencyJobPostByAgencyJobPostID?.agencyJobPostskills.length === 0 ? '---' :
                        viewPost?.data?.AgencyJobPostByAgencyJobPostID?.agencyJobPostskills?.map((row, key) => { return row.description + ', ' })}
                </Text>
                <Text style={styles.title}>Requirements:</Text>
                <Text style={styles.description}>
                    {viewPost?.data?.AgencyJobPostByAgencyJobPostID?.agencyJobPostRequirements.length === 0 ? '---' :
                        viewPost?.data?.AgencyJobPostByAgencyJobPostID?.agencyJobPostRequirements?.map((row, key) => { return row.description + ', ' })}
                </Text>
                <Text style={styles.title}>Resources Required:</Text>
                <Text style={styles.description}>{!viewPost?.data?.AgencyJobPostByAgencyJobPostID?.no_of_resources_required ? '---' : viewPost?.data?.AgencyJobPostByAgencyJobPostID?.no_of_resources_required}</Text>
                <Text style={styles.title}>Start Date:</Text>
                <Text style={styles.description}>{!viewPost?.data?.AgencyJobPostByAgencyJobPostID?.start_date ? '---' : formatDate(viewPost?.data?.AgencyJobPostByAgencyJobPostID?.start_date)}</Text>
                <Text style={styles.title}>Start Time:</Text>
                <Text style={styles.description}>{!viewPost?.data?.AgencyJobPostByAgencyJobPostID?.start_time ? '---' : formatTimeFromDB(viewPost?.data?.AgencyJobPostByAgencyJobPostID?.start_time)}</Text>
                <Text style={styles.title}>End Time:</Text>
                <Text style={styles.description}>{!viewPost?.data?.AgencyJobPostByAgencyJobPostID?.end_time ? '---' : formatTimeFromDB(viewPost?.data?.AgencyJobPostByAgencyJobPostID?.end_time)}</Text>
                <Text style={styles.title}>Pay Rate:</Text>
                <Text style={styles.description}>{!viewPost?.data?.AgencyJobPostByAgencyJobPostID?.pay_rate ? '---' : viewPost?.data?.AgencyJobPostByAgencyJobPostID?.pay_rate}</Text>
                <Text style={styles.title}>Priority:</Text>
                <Text style={styles.description}>{!viewPost?.data?.AgencyJobPostByAgencyJobPostID?.priority ? '---' : viewPost?.data?.AgencyJobPostByAgencyJobPostID?.priority}</Text>
                <Text style={styles.title}>Employee Requirements:</Text>
                <Text style={styles.title}>Safety Shoes:</Text>
                <Text style={styles.description}>{!viewPost?.data?.AgencyJobPostByAgencyJobPostID?.safety_shoes ? 'No' : 'Yes'}</Text>
                <Text style={styles.title}>Safety Gloves:</Text>
                <Text style={styles.description}>{!viewPost?.data?.AgencyJobPostByAgencyJobPostID?.safety_gloves ? 'No' : 'Yes'}</Text>
                <Text style={styles.title}>Safety Jacket:</Text>
                <Text style={styles.description}>{!viewPost?.data?.AgencyJobPostByAgencyJobPostID?.safety_jacket ? 'No' : 'Yes'}</Text>
            </Page>
        </Document>
    )
}

export default PostedJobsPDF