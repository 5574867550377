import React, { Component } from "react";
import Logo from "./Logo";
import Navbar from "./Navbar/Navbar";
import HeaderConfig from "./HeaderConfig";
import img from "../../assets/img/whatup.png";
import { useHistory } from "react-router-dom";
class Header extends Component {
  componentDidMount() {
    window.addEventListener("scroll", this.isSticky);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.isSticky);
  }

  isSticky = (e) => {
    const header = document.querySelector("header");
    const scrollTop = window.scrollY;
    scrollTop >= 250
      ? header.classList.add("sticky")
      : header.classList.remove("sticky");
  };

  // render(){
  //     return (
  //         <header className="header-area">
  //             <div className="container">
  //                 <div className="row align-items-center">
  //                     <div className="col-5 col-lg-2">
  //                         <Logo/>
  //                     </div>

  //                     <div className="col-lg-7 d-none d-lg-block">
  //                         <div className="navigation-area mt-lg-3">
  //                             <Navbar />
  //                         </div>
  //                     </div>

  //                     <div className="col-7 col-lg-3">
  //                         <HeaderConfig logRegContentShow={this.props.logRegContentShow} mobileMenuShow={this.props.mobileMenuShow} />
  //                     </div>
  //                 </div>
  //             </div>
  //         </header>
  //     );

  render() {
    const StoredToken = localStorage.getItem("token")

    return (
      <header className="header-area">
        <div className="whatsapp_float">
          <a href="https://wa.me/14166880871" target="_blank">
            <img
              src={img}
              className="whatsupIMG"
              style={{ marginLeft: 20 }}
              alt="whatsupIMG"
            />
          </a>
        </div>
        <div class="container">
          <div className="row align-items-center">
            <div className="col-5 col-lg-2">
              <Logo />
            </div>

            <div className="col-lg-0 d-none d-lg-block">
              <div className="navigation-area mt-lg-3">
                <Navbar />
              </div>
            </div>

            <div className="col-7 col-lg-3">
              <HeaderConfig
                logRegContentShow={this.props.logRegContentShow}
                mobileMenuShow={this.props.mobileMenuShow}
                StoredToken
              />
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
