import React, { Fragment } from "react";
import Header from "../components/Header";
import Funfact from "../components/Funfact";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
import FAQs from "../assets/css/FAQs.css";
import PageHeader from "../components/PageHeader";

import questions from "../data/FAQs/faq.json";

import answer from "../data/FAQs/faq.json";
import Banner from "./Banner";

const EmployerFaQs = () => {
  return (
    <Fragment>
      <Header />

      <PageHeader
        bgImg={require("../assets/img/backgroundImages/employer-FAQ.jpg")}
        title="Employer FAQs"
      />
      <div className="container">
        <Banner>
          {questions.map((question) => (
            <Banner.Entity key={question.id}>
              <Banner.Question> {question.question}</Banner.Question>
              <Banner.Text>{question.answer}</Banner.Text>
            </Banner.Entity>
          ))}
        </Banner>
      </div>
      <Funfact classes="sp-top" />
      <Footer />
      <LoginRegister />
      <MobileMenu />
    </Fragment>
  );
};

export default EmployerFaQs;
