import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { MdArrowBack, MdArrowDownward, MdArrowForward, MdFileDownload } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { CANDIDATES_WITH_JOB_LINK, CANDIDATES_WITH_JOB_LINK_COUNT } from '../../helpers/graphQL/Queries';
import Navbar from './Layout/Navbar';
import Sidebar from './Layout/Sidebar';

const CandidateApplications = () => {

    //API starts here 

    const history = useHistory()
    const token = localStorage.getItem("token")
    if (!token) history.push('/')

    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [page, setPage] = useState(0)

    //Get candidates count
    const candidateCount = useQuery(CANDIDATES_WITH_JOB_LINK_COUNT)

    //Get paginated candidates
    const { data, error, loading, refetch } = useQuery(CANDIDATES_WITH_JOB_LINK, {
        variables: {
            skip: page * itemsPerPage,
            take: parseInt(itemsPerPage)
        }
    })
    useEffect(() => {
        refetch()
    }, [])

    return (

        <>
            <Navbar />
            <div className="row m-0 p-0">
                <Sidebar />
                <div className="col" style={{ marginTop: '100px' }}>
                    <h3 className='red-clr-b' style={{ textAlign: 'center' }}>Candidate Applications</h3>
                    <section className="" style={{ paddingTop: 10 }}>
                        <div className="">
                            <div style={{ display: 'flex', justifyContent: 'space-between  ' }}>
                                <div>
                                    Total Count: {candidateCount?.data?.TotalNumberOfCandidatesWithJobLink}
                                </div>

                                <div>
                                    <select
                                        className="form-control"
                                        defaultValue={itemsPerPage}
                                        onChange={e => {
                                            setItemsPerPage(e.target.value)
                                            setPage(0)
                                        }}
                                    >
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={30}>30</option>
                                        <option value={40}>40</option>
                                        <option value={50}>50</option>
                                    </select>
                                </div>
                            </div>

                            <table className="table table-bordered table-hover text-center mt-3">
                                <thead>
                                    <tr className='table-active'>
                                        <th className=''>Ref #</th>
                                        <th className=''>Name</th>
                                        <th className=''>Email</th>
                                        <th className=''>Telephone</th>
                                        <th className=''>City</th>
                                        <th className=''>Job Sector</th>
                                        <th className=''>Resume</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading || candidateCount?.loading ?
                                        <tr>
                                            <td colSpan={7}>
                                                <div className="d-flex justify-content-center my-5">
                                                    <div className="spinner-grow text-danger" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        :
                                        data.AllCandidatesWithJobLink.length === 0 ?
                                            <div className="d-flex justify-content-center my-5">
                                                <span>No records found.</span>
                                            </div>
                                            :
                                            data.AllCandidatesWithJobLink.map((row, key) =>
                                                <tr key={key}>
                                                    <td className=''>{row.candidate_id}</td>
                                                    <td className=''>{row.first_name} {row.last_name}</td>
                                                    <td className=''>{row.email}</td>
                                                    <td className=''>{row.phone_number}</td>
                                                    <td className=''>{row.city}</td>
                                                    <td className=''>{row.job_sector}</td>
                                                    <td className=''>
                                                        <OverlayTrigger placement="top"
                                                            overlay={
                                                                <Tooltip id={`tooltip-top`}>Click to View or Download resume.</Tooltip>
                                                            }
                                                        >
                                                            <a href={row.resume} >
                                                                <MdFileDownload className='red-clr' style={{ fontSize: "20" }} />
                                                            </a>
                                                        </OverlayTrigger>
                                                    </td>
                                                </tr>
                                            )}
                                </tbody>
                            </table>

                            {!loading &&
                                <div >
                                    <ul className="pagination" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <li className="page-item mr-3">
                                            <button
                                                className="page-link"
                                                aria-label="Previous"
                                                disabled={!page} onClick={() => setPage(prev => prev - 1)}
                                            >
                                                <span aria-hidden="true"><MdArrowBack className='red-clr' style={{ fontSize: "20" }} /></span>
                                                <span className="sr-only">Previous</span>
                                            </button>
                                        </li>
                                        {page + 1} of {Math.round((candidateCount?.data?.TotalNumberOfCandidatesWithJobLink / itemsPerPage) + 0.5)}
                                        <li className="page-item ml-3">
                                            <button
                                                className="page-link"
                                                aria-label="Next"
                                                disabled={page >= (Math.round((candidateCount?.data?.TotalNumberOfCandidatesWithJobLink / itemsPerPage) + 0.5) - 1)}
                                                onClick={() => setPage(prev => prev + 1)}
                                            >
                                                <span aria-hidden="true"><MdArrowForward className='red-clr' style={{ fontSize: "20" }} /></span>
                                                <span className="sr-only">Next</span>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            }

                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default CandidateApplications