import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { GET_AGENCY_JOB_POST_BY_AGENCY_JOB_POST_ID, GET_ALL_AGENCY_JOB_POSTS, GET_ALL_AGENCY_JOB_POST_COUNT, UPDATE_AGENCY_JOB_POST_PRIORITY_BY_AGENCY_JOB_POST_ID } from '../../helpers/graphQL/Queries';
import { DELETE_AGENCY_JOB_POST } from '../../helpers/graphQL/Mutations';
import { MdArrowBack, MdArrowForward, MdDelete, MdRemoveRedEye } from 'react-icons/md';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { formatDate, formatDate1, formatTimeFromDB, NullToEmptyString, validateEmpty } from '../utils/utils';
import { PDFDownloadLink } from '@react-pdf/renderer'
import PostedJobsPDF from './PostedJobsPDF';
import Navbar from './Layout/Navbar';
import Sidebar from './Layout/Sidebar';
import DeleteConfirmation from './Popups/DeleteConfirmation';

const PostedJobs = () => {


  //API starts here
  const history = useHistory()
  const token = localStorage.getItem("token")
  if (!token) history.push('/')

  const [firstRender, setFirstRender] = useState(true)
  const [itemsPerPage, setItemsPerPage] = useState(5)
  const [page, setPage] = useState(0)

  const [openViewModal, setOpenViewModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [deleteID, setDeleteID] = useState("")
  const [viewID, setViewID] = useState("")

  const jobCount = useQuery(GET_ALL_AGENCY_JOB_POST_COUNT)

  //Get all paginated jobs
  const agencyJobs = useQuery(GET_ALL_AGENCY_JOB_POSTS, {
    variables: {
      skip: page * itemsPerPage,
      take: parseInt(itemsPerPage)
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    agencyJobs.refetch()
  }, [])


  //Delete a job
  const [deltePostFunc, deletePost] = useMutation(DELETE_AGENCY_JOB_POST)

  const onClickDeleteHandler = async () => {
    await deltePostFunc({ variables: { agencyJobPostJobId: deleteID } })
    setDeleteID("")
    setOpenDeleteModal(!openDeleteModal)
    window.location.reload()
  }

  //View a job
  const [viewPostFunc, viewPost] = useLazyQuery(GET_AGENCY_JOB_POST_BY_AGENCY_JOB_POST_ID, {
    variables: { agencyJobPostJobId: parseInt(viewID), pollInterval: 1000 }
  })

  useEffect(() => {
    setFirstRender(false)
    if (!firstRender) {
      viewPostFunc()
    }
  }, [viewID])

  //Update Job priority
  const [updatePriorityFunc, updatePriority] = useMutation(UPDATE_AGENCY_JOB_POST_PRIORITY_BY_AGENCY_JOB_POST_ID, {
    onCompleted: (data) => {
      agencyJobs.refetch()
    }
  })

  const handlePriorityState = async (e, row) => {
    await updatePriorityFunc({ variables: { agencyJobPostJobId: parseInt(row.agency_job_post_job_id), priority: parseInt(e.target.value) } })
  }

  const setPageNextHandler = () => {
    setPage(prev => prev + 1)
  }
  const setPagePrevHandler = () => {
    setPage(prev => prev - 1)
  }

  return (
    <>
      <Navbar />
      <div className="row m-0 p-0">
        <Sidebar />
        <div className="col" style={{ marginTop: '100px' }}>
          <h3 className='red-clr-b' style={{ textAlign: 'center' }}>Posted Jobs</h3>
          <section className="" style={{ paddingTop: 10 }}>
            <div className="">
              <div style={{ display: 'flex', justifyContent: 'space-between  ' }}>
                <div>Total Records: {jobCount?.data?.TotalNumberOfAgencyPostedJobs}</div>
                <div>
                  <select
                    className="form-control"
                    value={itemsPerPage}
                    onChange={e => {
                      setItemsPerPage(e.target.value)
                      setPage(0)
                    }}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={40}>40</option>
                    <option value={50}>50</option>
                  </select>
                </div>
              </div>
              <table className="table table-bordered table-hover text-center mt-3">
                <thead>
                  <tr className='table-active'>
                    <th className=''>Ref #</th>
                    <th className=''>Added Date</th>
                    <th className=''>Company Name</th>
                    <th className=''>Job Title</th>
                    <th className=''>Job Sector</th>
                    <th colSpan={2} className=''>Actions</th>
                    <th className=''>Priority</th>
                  </tr>
                </thead>
                <tbody>
                  {agencyJobs?.loading || jobCount?.loading ?
                    <tr>
                      <td colSpan={8}>
                        <div className="d-flex justify-content-center my-5">
                          <div className="spinner-grow text-danger" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                    :

                    agencyJobs.data.AllAgencyJobPosts.length === 0 ?
                      <div className="d-flex justify-content-center my-5">
                        <span>No records found.</span>
                      </div>
                      :
                      agencyJobs.data.AllAgencyJobPosts.map((row, key) =>
                        <tr key={key}>
                          <td className=''>{row.agency_job_post_job_id}</td>
                          <td className=''>{formatDate1(row.created_at)}</td>
                          <td className=''>{row.company_name}</td>
                          <td className=''>{row.job_title}</td>
                          <td className=''>{row.job_sector}</td>
                          <td className=''>
                            <OverlayTrigger placement="top"
                              overlay={
                                <Tooltip id={`tooltip-top`}>Click to view details.</Tooltip>
                              }
                            >
                              <button
                                value={row.agency_job_post_job_id}
                                onClick={e => {
                                  setOpenViewModal(!openViewModal)
                                  setViewID(row.agency_job_post_job_id)
                                }}
                              >
                                <MdRemoveRedEye className='red-clr' style={{ fontSize: "20" }} />
                              </button>
                            </OverlayTrigger>
                          </td>

                          <td className=''>
                            <OverlayTrigger placement="top"
                              overlay={
                                <Tooltip id={`tooltip-top`}>Click to delete this job.</Tooltip>
                              }
                            >
                              <button
                                className='red-clr'
                                value={row.agency_job_post_job_id}
                                onClick={e => {
                                  setOpenDeleteModal(!openDeleteModal)
                                  setDeleteID(row.agency_job_post_job_id)
                                }}
                              >
                                <MdDelete style={{ fontSize: "20" }} />
                              </button>
                            </OverlayTrigger>
                          </td>
                          <td>
                            <select
                              disabled={updatePriority?.loading && true}
                              name="priorityState"
                              value={parseInt(row.priority)}
                              onChange={e => handlePriorityState(e, row)}
                            >
                              <option value={0}>--Select an option--</option>
                              <option value={1}>1</option>
                              <option value={2}>2</option>
                              <option value={3}>3</option>
                              <option value={4}>4</option>
                              <option value={5}>5</option>
                            </select>
                          </td>
                        </tr>
                      )
                  }
                </tbody>
              </table>

              <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={openViewModal}
              >
                <Modal.Header id="contained-modal-title-vcenter">
                  <h3>Posted Job Details</h3>
                </Modal.Header>
                <Modal.Body>
                  {viewPost?.loading || !viewPost?.data || viewPost?.data === undefined || viewPost?.data?.AgencyJobPostByAgencyJobPostID === null
                    ?
                    <div className="d-flex justify-content-center my-5" >
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                    :
                    <>
                      <table className='table table-bordered table-popups'>
                        <thead className="thead-light">
                          <tr>
                            <th colSpan={2} className='text-center'>Company Detials</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th className='text-primary-st'>Ref No</th>
                            <td>{viewPost.data.AgencyJobPostByAgencyJobPostID.agency_job_post_job_id}</td>
                          </tr>
                          <tr>
                            <th className='text-primary-st'>Company Name</th>
                            <td>{viewPost.data.AgencyJobPostByAgencyJobPostID.company_name}</td>
                          </tr>
                          <tr>
                            <th className='text-primary-st'>Job Title</th>
                            <td>{viewPost.data.AgencyJobPostByAgencyJobPostID.job_title}</td>
                          </tr>
                          <tr>
                            <th className='text-primary-st'>Location</th>
                            <td>{viewPost.data.AgencyJobPostByAgencyJobPostID.location}</td>
                          </tr>
                          <tr>
                            <th className='text-primary-st'>Working Location</th>
                            <td>{viewPost.data.AgencyJobPostByAgencyJobPostID.working_location}</td>
                          </tr>
                        </tbody>
                      </table>
                      <br></br>

                      <table className='table table-bordered table-popups'>
                        <thead className="thead-light">
                          <tr>
                            <th colSpan={2} className='text-center'>Job Detials</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th className='text-primary-st'>Language</th>
                            <td>{viewPost.data.AgencyJobPostByAgencyJobPostID.language}</td>
                          </tr>
                          <tr>
                            <th className='text-primary-st'>Country</th>
                            <td>{viewPost.data.AgencyJobPostByAgencyJobPostID.country}</td>
                          </tr>
                          <tr>
                            <th className='text-primary-st'>Job Sector</th>
                            <td>{viewPost.data.AgencyJobPostByAgencyJobPostID.job_sector}</td>
                          </tr>
                          <tr>
                            <th className='text-primary-st'>Job Description</th>
                            <td>{viewPost.data.AgencyJobPostByAgencyJobPostID.job_description}</td>
                          </tr>
                          <tr>
                            <th className='text-primary-st'>Required Qualification</th>
                            <td>{viewPost.data.AgencyJobPostByAgencyJobPostID.required_qualification}</td>
                          </tr>
                          <tr>
                            <th className='text-primary-st'>Skills</th>
                            <td>{viewPost.data.AgencyJobPostByAgencyJobPostID.agencyJobPostskills.map((item, key) => (key + 1) + ". " + (item.description) + "  ")}</td>
                          </tr>
                          <tr>
                            <th className='text-primary-st'>Requirements</th>
                            <td>{viewPost.data.AgencyJobPostByAgencyJobPostID.agencyJobPostRequirements.map((item, key) => (key + 1) + ". " + item.description + "  ")}</td>
                          </tr>
                          <tr>
                            <th className='text-primary-st'>Resources Required</th>
                            <td>{validateEmpty(viewPost.data.AgencyJobPostByAgencyJobPostID.no_of_resources_required)}</td>
                          </tr>
                          <tr>
                            <th className='text-primary-st'>Start Date</th>
                            <td>{formatDate(viewPost.data.AgencyJobPostByAgencyJobPostID.start_date)}</td>
                          </tr>
                          <tr>
                            <th className='text-primary-st'>Start Time</th>
                            <td>{formatTimeFromDB(viewPost.data.AgencyJobPostByAgencyJobPostID.start_time) + " " + validateEmpty(viewPost.data.AgencyJobPostByAgencyJobPostID.start_time_zone)}</td>
                          </tr>
                          <tr>
                            <th className='text-primary-st'>End Time</th>
                            <td>{formatTimeFromDB(viewPost.data.AgencyJobPostByAgencyJobPostID.end_time) + " " + validateEmpty(viewPost.data.AgencyJobPostByAgencyJobPostID.end_time_zone)}</td>
                          </tr>
                          <tr>
                            <th className='text-primary-st'>Pay Rate</th>
                            <td>{validateEmpty(viewPost.data.AgencyJobPostByAgencyJobPostID.pay_rate) + ' ' + validateEmpty(viewPost.data.AgencyJobPostByAgencyJobPostID.pay_rate_currency)}</td>
                          </tr>
                          <tr>
                            <th className='text-primary-st'>Priority</th>
                            <td>{viewPost.data.AgencyJobPostByAgencyJobPostID.priority}</td>
                          </tr>
                          <tr>
                            <th className='text-primary-st'>Employee Requirements</th>
                            <td>
                              <div>
                                Safety Shoes: <span className=' text-primary-st'>{viewPost.data.AgencyJobPostByAgencyJobPostID.safety_shoes ? 'Yes' : 'No'}</span>
                              </div>
                              <div>
                                Safety Gloves: <span className=' text-primary-st'>{viewPost.data.AgencyJobPostByAgencyJobPostID.safety_gloves ? 'Yes' : 'No'}</span>
                              </div>
                              <div>
                                Safety Jacket: <span className=' text-primary-st'>{viewPost.data.AgencyJobPostByAgencyJobPostID.safety_jacket ? 'Yes' : 'No'}</span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  }
                </Modal.Body>
                <Modal.Footer>
                  <PDFDownloadLink
                    document={<PostedJobsPDF viewPost={viewPost} />}
                    fileName={`Posted Job #${viewID}`}
                  >
                    {viewPost && <Button variant='success'>Download form</Button>}
                  </PDFDownloadLink>
                  <Button variant='danger' onClick={() => setOpenViewModal(!openViewModal)}>Close</Button>
                </Modal.Footer>
              </Modal>

              <DeleteConfirmation
                show={openDeleteModal}
                heading="Delete Job"
                text={`Are you sure you want to delete the job with Ref# ${deleteID}?`}
                onClickSuccess={onClickDeleteHandler}
                onClickCancel={() => { setOpenDeleteModal(!openDeleteModal) }}
              />

              {!agencyJobs?.loading &&
                <div >
                  <ul className="pagination" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <li className="page-item mr-3">
                      <button
                        className="page-link"
                        aria-label="Previous"
                        disabled={!page} onClick={setPagePrevHandler}
                      >
                        <span aria-hidden="true"><MdArrowBack className='red-clr' style={{ fontSize: "20" }} /></span>
                        <span className="sr-only">Previous</span>
                      </button>
                    </li>
                    {page + 1} of {Math.round((jobCount?.data?.TotalNumberOfAgencyPostedJobs / itemsPerPage) + 0.5)}
                    <li className="page-item ml-3">
                      <button
                        className="page-link"
                        aria-label="Next"
                        disabled={page >= (Math.round((jobCount?.data?.TotalNumberOfAgencyPostedJobs / itemsPerPage) + 0.5) - 1)}
                        onClick={setPageNextHandler}
                      >
                        <span aria-hidden="true"><MdArrowForward className='red-clr' style={{ fontSize: "20" }} /></span>
                        <span className="sr-only">Next</span>
                      </button>
                    </li>
                  </ul>
                </div>
              }
              {/* <div><span className='red-clr'>*</span> Please view the job by clicking on <MdRemoveRedEye style={{ fontSize: "20" }} className='red-clr' /> icon in order to change the <span className='text-danger'>Proprity</span>.</div> */}
            </div>
          </section>
        </div>
      </div >
    </>
  );
}


export default PostedJobs


