import React from "react";
import From from "./From";
import ContactInfo from "./ContactInfo";

const ContactPage = () => {
  return (
    <div className={"contact-page-area-wrapper sp-y"}>
      <div className="container">
        <div className="contact-content-wrap">
          <div className="row">
            <div className="col-lg-8">
              <div className="contact-form-area contact-method">
                <h3 style={{ color: "red" }}>Send us a Message</h3>
                <From />
              </div>
            </div>

            <div className="col-lg-4">
              <div
                className="contact-information contact-method"
                style={{
                  backgroundImage: `url(${require("../../assets/img/Contacts1.png")})`,
                  backgroundRepeat: "no-repeat center center fixed",
                }}
              >
                <div
                  style={{ marginTop: "205px" }}
                  className="contact-info-con"
                >
                  <h3>Contact Info</h3>
                  <ContactInfo address="1315 Derry Road East, Unit 1 <br/>Mississauga, Ontario L5T 1B6 <br/>info@sprostaffing.com <br/>(+1) 800 640 1333  <br/>(+1) 416-688-0871" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
