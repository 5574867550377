// import Navbar from '../components/layout/DashboardNavbar';
// import Sidebar from '../components/layout/DashboardSidebar';
import React from "react"
import { useHistory } from "react-router-dom"
import Dashboard from "./Dashboard"


const DashboardMain = () => {
    
    const history = useHistory()
    if (!localStorage.getItem('token')) history.push('/')

    return (
        <Dashboard />
    )
}

export default DashboardMain