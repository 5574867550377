import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import navbarData from "../../../data/Navbar/navbar";

function NavbarItem(props) {
  const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
      const media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }
      const listener = () => setMatches(media.matches);
      window.addEventListener("resize", listener);
      return () => window.removeEventListener("resize", listener);
    }, [matches, query]);

    return matches;
  };

  let isDesktop = useMediaQuery("(min-width: 1200px)");
  

  return navbarData.map((item) => (
    <>
      {
        <li
          key={item.id}
          className={item.subMenu || item.megaMenu ? "has-submenu" : ""}
        >
          <Link activeClassName="active-link" to={`${item.link}`}>
            {console.log(isDesktop ? "Desktop" : "Mobile")}
            {item.id == 4
              ? isDesktop
                ? "About us"
                : "About"
              : item.id == 5
              ? isDesktop
                ? "Contact us"
                : "Contact"
              : item.id == 2
              ? isDesktop
                ? "Candidates"
                : "Candidate"
              : item.title}
          </Link>
          {(() => {
            if (item.subMenu) {
              return (
                <ul className="submenu-nav">
                  {item.subMenu.map((subItem, index) => {
                    return (
                      <li key={index}>
                        <NavLink
                          activeClassName="active-link"
                          to={`${subItem.link}`}
                        >
                          {subItem.title}
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
              );
            }

            if (item.megaMenu) {
              return (
                <ul className="submenu-nav submenu-nav-mega">
                  {item.megaMenu.map((megaItem, indx) => (
                    <li key={indx} className="mega-menu-item">
                      <Link to={megaItem.link}>{megaItem.title}</Link>
                      <ul>
                        {megaItem.lists.map((listItem, idx) => {
                          return (
                            <li key={idx}>
                              <Link to={`${listItem.link}`}>
                                {listItem.title}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  ))}
                </ul>
              );
            }
          })()}
        </li>
      }
    </>
  ));
}

export default NavbarItem;
